import { useReducer, createContext, useMemo } from 'react';
import stepReducer, { initialState } from '../reducers/stepReducer';
import { StepContextType } from '../types/stepsTypes';

export const StepContext = createContext<StepContextType>({
  state: { currentStep: -1, alreadyApplied: false },
  dispatch: () => -1,
});

const StepProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(stepReducer, initialState);

  const values = useMemo(() => ({ state, dispatch }), [state]);

  return <StepContext.Provider value={values}>{children}</StepContext.Provider>;
};

export default StepProvider;
