import React from 'react';

// Components
import {
  CheckInput,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { Col } from 'reactstrap';

// Hooks
import {
  Control,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useValidations } from '../../../../hooks/useValidations';

// I18n
import { useTranslation } from 'react-i18next';

// Type
import { RegisterFormFields } from '../types';
import { TEXTINPUT_MAXLENGTH } from '../../../../config/constants';
import LengthCounter from '../../../../components/Form/LengthCounter';

interface Props {
  isEditForm?: boolean;
  control: Control<RegisterFormFields>;
  unregister: UseFormUnregister<RegisterFormFields>;
  watch: UseFormWatch<RegisterFormFields>;
  setValue: UseFormSetValue<RegisterFormFields>;
  countries: SelectOptionType[];
  regions: SelectOptionType[];
  communes: SelectOptionType[];
}

// Render
export default function PersonalInformation({
  isEditForm = false,
  control,
  watch,
  setValue,
  unregister,
  regions,
  communes,
  countries,
}: Props) {
  const usePassport = watch('usePassport');
  const { t } = useTranslation();

  const { validateTextNotEmpty, validatePhone, validateEmail, validateRut } =
    useValidations();

  const handleMark = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setValue('usePassport', value);
    if (value) {
      unregister('rut');
    } else {
      unregister(['passport', 'country']);
    }
  };

  return (
    <>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">
          {t(`views.account.registration.form.personalInformation`)}
        </p>
      </Col>
      {!usePassport && (
        <Col xs={12} md={5}>
          <TextInput
            label={t('common.terms.rut')}
            type="text"
            name="rut"
            control={control}
            disabled={!!isEditForm}
            formatter="rut"
            rules={{
              validate: {
                empty: validateTextNotEmpty,
                rut: validateRut,
              },
            }}
            maxLength={TEXTINPUT_MAXLENGTH}
          />
        </Col>
      )}
      {usePassport && (
        <>
          <Col xs={12} md={5}>
            <TextInput
              label={t('common.terms.passport')}
              name="passport"
              disabled={!!isEditForm}
              control={control}
              rules={{ required: t('common.validations.required') }}
              maxLength={TEXTINPUT_MAXLENGTH}
            />
          </Col>
        </>
      )}

      <Col xs={12} md={7}>
        <TextInput
          label={t('common.terms.names')}
          name="names"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
      </Col>
      <Col xs={12}>
        <CheckInput
          value={usePassport}
          label="Ingresar pasaporte"
          name="bool"
          disabled={!!isEditForm}
          onChange={handleMark}
        />
      </Col>
      <Col xs={12} md={6}>
        <TextInput
          label={t('common.terms.paternalLastname')}
          name="paternalLastName"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
      </Col>
      <Col xs={12} md={6}>
        <TextInput
          label={t('common.terms.maternalLastname')}
          name="maternalLastName"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          control={control}
          name="country"
          label={t('common.terms.nationality')}
          rules={{ required: t('common.validations.required') }}
          options={countries}
        />
      </Col>
      <Col xs={12} md={6}>
        <TextInput
          label={t('common.terms.profession')}
          name="profession"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          control={control}
          name="region"
          label={t('common.terms.region')}
          rules={{ required: t('common.validations.required') }}
          options={regions}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          control={control}
          name="commune"
          label={t('common.terms.commune')}
          rules={{ required: t('common.validations.required') }}
          options={communes}
        />
      </Col>
      <Col xs={12} md={6}>
        <TextInput
          label={t('common.terms.city')}
          name="city"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={125}
        />
      </Col>
      <Col xs={12} md={6}>
        <TextInput
          label={t('common.terms.residence')}
          name="residence"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={125}
        />
        <LengthCounter name="residence" maxLength={125} />
      </Col>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">DATOS DE CONTACTO</p>
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          label={t('common.terms.phone')}
          placeholder="+56XXXXXXXXX"
          name="phone"
          control={control}
          formatter="phone"
          rules={{
            validate: {
              empty: validateTextNotEmpty,
              phone: validatePhone,
            },
          }}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
      </Col>
      <Col xs={12} md={4}>
        <TextInput
          name="email"
          type="text"
          disabled={!!isEditForm}
          control={control}
          label={t('common.terms.email')}
          tooltip={t('views.account.registration.form.emailInformation')}
          rules={{
            validate: {
              empty: validateTextNotEmpty,
              email: validateEmail,
            },
          }}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
      </Col>
    </>
  );
}
