import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

// Components
import {
  addToast,
  Button,
  FileInputControlled,
  Modal,
} from '@octano/global-ui';

// Hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useUploadFileMutation } from '../api';

// Types
import { RequiredFile } from '../types';
import { useValidations } from '../../../hooks/useValidations';

export type ModalUploadDocumentMethods = {
  open: (requirement: RequiredFile) => void;
  close: () => void;
};

type ModalUploadDocumentProps = {
  onConfirm?: (requirement: RequiredFile) => void;
};

type UploadPayload = {
  document: File | null;
};

const keyPrefix = 'views.postulations.requirements.modalUpload';

// Render
const ModalUploadDocument = (
  { onConfirm }: ModalUploadDocumentProps,
  ref: React.Ref<ModalUploadDocumentMethods>,
) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { validateTextNotEmpty, validateFileSize } = useValidations();

  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [requirement, setRequirement] = useState<RequiredFile>();

  const { control, watch, reset, setValue, handleSubmit } =
    useForm<UploadPayload>({
      defaultValues: {
        document: null,
      },
    });

  const [document] = watch(['document']);

  const submitEnabled = useMemo(() => {
    return !!document;
  }, [document]);

  const handleOpen = useCallback(
    (props: RequiredFile) => {
      setIsOpen(true);
      setRequirement(props);
      setValue('document', null);
    },
    [setValue],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setRequirement(undefined);
    reset();
  }, [reset]);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleConfirm = async (params: UploadPayload) => {
    if (!requirement?.name || !params?.document || isUploading) {
      return;
    }
    try {
      const formData = new FormData();
      formData?.append('document', params?.document);
      formData?.append('name', requirement?.name);
      await uploadFile(formData).unwrap();
      addToast({
        icon: 'check',
        color: 'success',
        text: t('success'),
      });

      handleClose();
      onConfirm && onConfirm(requirement);
    } catch (_error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('failed'),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-review d-flex w-100 flex-column align-items-center">
        <span className="d-block w-100 text-dark fs-22 lh-30 text-center mb-0">
          {requirement?.displayName?.trim() || ''}
        </span>
        <span className="d-block w-100 text-light fs-18 lh-30 text-center mb-4">
          {t('subtitle')}
        </span>

        <div className="w-100 mb-4">
          <FileInputControlled
            label={t('file')}
            name="document"
            btnText={t('upload')}
            control={control}
            accept="image/png, image/jpeg, application/pdf"
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                fileSize: validateFileSize,
              },
            }}
          />
        </div>

        <div className="container-fluid px-0 mt-4">
          <div className="row wrap">
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                loading={isUploading}
                onClick={handleClose}
                text={t('cancel')}
                outlined={true}
              />
            </div>
            <div className="col-12 col-md-6">
              <Button
                loading={isUploading}
                className="w-100 mb-2"
                onClick={handleSubmit(handleConfirm)}
                text={t('confirm')}
                disabled={!submitEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalUploadDocument);
