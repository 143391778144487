import {
  Button,
  OutlinedSelectOptionType,
  Select,
  TextInput,
  useMobile,
} from '@octano/global-ui';
import React, { Fragment, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { ResumeFormField } from '../types';

interface Props {
  educationalLevels: OutlinedSelectOptionType[];
  collegeDegrees: OutlinedSelectOptionType[];
  educationalInformation: any[];
}
export default function AcademicInformation({
  educationalLevels,
  collegeDegrees,
  educationalInformation,
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const { control } = useFormContext<ResumeFormField>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educationalInformation',
  });

  const academicInformation = useMemo<JSX.Element>(
    () => (
      <>
        {fields.map((item, i) => (
          <Fragment key={item.id}>
            <Col xs={12} md={4}>
              <Select
                label={t('common.terms.educationalLevel')}
                placeholder={t('common.terms.educationalLevel')}
                options={educationalLevels}
                name={`educationalInformation.${i}.educationalLevel` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
              />
            </Col>
            <Col xs={12} md={4}>
              <Select
                label={t('common.terms.title')}
                placeholder={t('common.terms.title')}
                options={collegeDegrees}
                name={`educationalInformation.${i}.collegeDegree` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
              />
            </Col>
            <Col xs={12} md={3}>
              <TextInput
                label={t('common.terms.houseOfStudies')}
                placeholder={t('common.terms.houseOfStudies')}
                name={`educationalInformation.${i}.college` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
              />
            </Col>
            <Col xs={12} md={1}>
              <Button
                icon="trash"
                size="lg"
                className="w-100 mb-3"
                outlined
                onClick={() => {
                  remove(i);
                }}
              />
            </Col>
          </Fragment>
        ))}
      </>
    ),
    [collegeDegrees, control, educationalLevels, fields, remove, t],
  );

  const educationalInformationList = useMemo(
    () =>
      educationalInformation.map((ei, i) => (
        <Fragment key={Number(i)}>
          <Col xs={12} md={4}>
            <TextInput
              label={t('common.terms.educationalLevel')}
              placeholder={t('common.terms.educationalLevel')}
              name={`educationalLevel.${i}` as const}
              value={ei.educationalLevel.name}
              disabled
            />
          </Col>
          <Col xs={12} md={4}>
            <TextInput
              label={t('common.terms.title')}
              placeholder={t('common.terms.title')}
              name={`collegeDegree.${i}` as const}
              value={ei.collegeDegree.name}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <TextInput
              label={t('common.terms.houseOfStudies')}
              placeholder={t('common.terms.houseOfStudies')}
              name={`college.${i}` as const}
              value={ei.college}
              disabled
            />
          </Col>
        </Fragment>
      )),
    [educationalInformation, t],
  );

  return (
    <>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">
          {t('views.account.registration.form.academicData')}
        </p>
      </Col>
      {educationalInformationList}
      {academicInformation}
      <Col xs={12}>
        <Button
          size="md"
          text={'+ ' + t('common.actions.newCollegeDegree')}
          className="float-right mt-4"
          style={{ width: isMobile ? '100%' : '199.81px' }}
          onClick={() =>
            append({
              educationalLevel: null,
              collegeDegree: null,
              college: null,
            })
          }
        />
      </Col>
    </>
  );
}
