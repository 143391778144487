import { FileInput, Icon } from '@octano/global-ui';
import { useRef, useState } from 'react';
import { FieldValues, UseFormClearErrors } from 'react-hook-form';
import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap';

import { useValidations } from '../../../../../hooks/useValidations';
import styles from './file.module.scss';

interface FileFormProps {
  submitFunction?: (values: any) => void;
  onSuccess?: () => void;
  clearErrors?: UseFormClearErrors<any>;
  isSubmitting?: boolean;
  name: string;
  title?: string;
  accept?: string;
  btnText?: string;
  disable?: boolean;
  information?: string;
  error?: string;
}

export default function FileUpload(props: FileFormProps) {
  const {
    submitFunction = (values: FieldValues) => console.log(values),
    name,
    title,
    information,
    accept = 'image/png, image/jpeg, application/pdf',
    disable = false,
    btnText = 'CARGAR DOCUMENTO',
    error,
  } = props;

  const titleRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { validateTextNotEmpty, validateFileSize } = useValidations();

  const onChangeFile = (e: File) => {
    const [notEmpty, fileSize] = [validateTextNotEmpty(e), validateFileSize(e)];
    if (typeof notEmpty === 'string') {
      setErrorMessage(notEmpty);
    } else if (typeof fileSize === 'string') {
      setErrorMessage(fileSize);
    } else {
      setErrorMessage('');
      submitFunction(e);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className={styles.fileContainer}>
          {title && (
            <h5 className="text-primary fs-20 pl-0 fw-700 mb-3 text-uppercase">
              {title}
              {information && (
                <span ref={titleRef} className="ml-1">
                  <Icon name="information" className="mb-1" />
                </span>
              )}
            </h5>
          )}

          <FileInput
            name={name}
            label={''}
            accept={accept}
            disabled={disable}
            btnText={btnText}
            maxFiles={1}
            error={errorMessage || error}
            onChange={onChangeFile}
          />

          <UncontrolledTooltip placement="auto" target={titleRef}>
            {information}
          </UncontrolledTooltip>
        </Col>
      </Row>
    </Container>
  );
}
