// Components
import { Icon } from '@octano/global-ui';
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { useGetPrivatesCountQuery } from '../../private-offers/api';

// Render
const PrivateAlert = () => {
  const prefix = 'views.jobsList.privateAlert';
  const { t } = useTranslation();
  const { data: count = 0, isFetching } = useGetPrivatesCountQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  if (!count || isFetching) {
    return <></>;
  }

  return (
    <div
      className="g-alert g-alert-lg w-100 alert alert-primary fade show"
      role="alert"
    >
      <div className="d-flex align-items-center">
        <div className="icon-btn">
          <Icon name="information" color="primary" size={36} />
        </div>
        <span className="mr-1">{t(`${prefix}.counter`, { count })}</span>
        <Link to="/private/offers">{t(`${prefix}.clickToGo`)}</Link>
      </div>
    </div>
  );
};

export default PrivateAlert;
