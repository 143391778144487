import AuthLayout from '../layouts/AuthLayout';
import NavbarLayout from '../layouts/NavbarLayout';
import NavbarLayoutWithAuth from '../layouts/NavbarLayoutWithAuth';
import { PermissionName } from '../types/auth';
import Login from '../views/account/login';
import PasswordRecoveryFinish from '../views/account/recovery/PasswordRecoveryFinish';
import PasswordRecoveryForm from '../views/account/recovery/PasswordRecoveryForm';
import Register from '../views/account/register';
import RegistrationForm from '../views/account/register/RegistrationForm';
import JobDetailPage from '../views/jobDetail';
import JobsOfferPage from '../views/jobsList';
import postulation from '../views/postulation';
import PrivateOffers from '../views/private-offers';
import MyPostulations from '../views/postulations';
import PostulationDetailScreen from '../views/postulations/detail';
import EditProfileScreen from '../views/account/edit-profile';
import ChangePasswordScreen from '../views/account/password';

export enum PathsLayouts {
  public = '/public',
  auth = '/auth',
  jobs = '/jobs',
  postulation = '/postulation',
  myPostulations = '/postulations',
  private = '/private',
  profile = '/profile',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]: {
    layoutComponent: (props: any) => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
    requiredPermissions?: PermissionName[];
    allPermissionsRequired?: boolean;
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: AuthLayout,
    defaultPath: '/login',
    views: [
      { path: '/login', component: Login },
      {
        path: '/reset-password',
        component: PasswordRecoveryForm,
      },
      {
        path: '/account/recovery/finish',
        component: PasswordRecoveryFinish,
      },
    ],
  },

  [PathsLayouts.public]: {
    layoutComponent: NavbarLayout,
    defaultPath: '/jobs',
    views: [
      {
        path: '/jobs',
        component: JobsOfferPage,
      },
      {
        path: '/jobs/detail/:id',
        component: JobDetailPage,
      },
      { path: '/registration', component: Register },
      { path: '/registration/finish', component: RegistrationForm },
    ],
  },

  [PathsLayouts.jobs]: {
    layoutComponent: NavbarLayout,
    defaultPath: '/detail',
    views: [
      {
        path: '/detail/:id',
        component: JobDetailPage,
      },
    ],
  },

  [PathsLayouts.postulation]: {
    layoutComponent: NavbarLayoutWithAuth,
    defaultPath: '/offer',
    views: [
      {
        path: '/offer/:offerId?/(apply)?/:postulationId?',
        component: postulation,
      },
    ],
  },

  [PathsLayouts.private]: {
    layoutComponent: NavbarLayoutWithAuth,
    defaultPath: '/jobs',
    views: [
      {
        path: '/jobs',
        component: PrivateOffers,
      },
    ],
  },

  [PathsLayouts.profile]: {
    layoutComponent: NavbarLayoutWithAuth,
    defaultPath: '/edit',
    views: [
      {
        path: '/edit',
        component: EditProfileScreen,
      },
      {
        path: '/password',
        component: ChangePasswordScreen,
      },
    ],
  },

  [PathsLayouts.myPostulations]: {
    layoutComponent: NavbarLayoutWithAuth,
    defaultPath: '',
    views: [
      {
        path: '',
        component: MyPostulations,
      },
      {
        path: '/detail/:postulationId',
        component: PostulationDetailScreen,
      },
    ],
  },
};

export default routes;
