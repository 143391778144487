import { useCallback, useMemo } from 'react';

import { ColumnTable, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { CurrentSteps } from '../../../types/stepsTypes';
import Legend from '../parts/Legend';
import Badge from '../parts/Badge';
import { AuthorizationStatus, OfferStatus } from '../types';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

const keyPrefix = 'views.postulations.list';

export default function usePostulationsColumns() {
  const { t } = useTranslation('translation', { keyPrefix });
  const history = useHistory();

  const goToPosulation = useCallback(
    (id: any) => {
      history.push({
        pathname: `/postulations/detail/${id}`,
      });
    },
    [history],
  );

  const offerStatuses: Record<OfferStatus, { color: string; text: string }> =
    useMemo(
      () => ({
        [OfferStatus.Vigente]: {
          color: '#CEF0DA',
          text: t('legends.vigente'),
        },
        [OfferStatus.Publicada]: {
          color: '#CEF0DA',
          text: t('legends.publicada'),
        },
        [OfferStatus.Pausada]: {
          color: '#FFE6A1',
          text: t('legends.pausada'),
        },
        [OfferStatus.Cerrada]: {
          color: '#F2C0BD',
          text: t('legends.cerrada'),
        },
        [OfferStatus.EnProceso]: {
          color: '#FFE6A1',
          text: t('legends.enProceso'),
        },
        [OfferStatus.En_Proceso]: {
          color: '#FFE6A1',
          text: t('legends.enProceso'),
        },
        [OfferStatus.Finalizada]: {
          color: '#CEF0DA',
          text: t('legends.finalizada'),
        },
      }),
      [t],
    );

  const authorizationStatuses: Record<
    AuthorizationStatus,
    { color: string; text: string }
  > = useMemo(
    () => ({
      [AuthorizationStatus.NotAuthorizedForHiring]: {
        color: '#FDC98C',
        text: t('authorizationStatuses.notAuthorizedForHiring'),
      },
      [AuthorizationStatus.DiscardedInHiring]: {
        color: '#F2C0BD',
        text: t('authorizationStatuses.discardedInHiring'),
      },
      [AuthorizationStatus.Hired]: {
        color: '#CEF0DA',
        text: t('authorizationStatuses.hired'),
      },
      [AuthorizationStatus.AuthorizedForHiring]: {
        color: '#DDEFFF',
        text: t('authorizationStatuses.authorizedForHiring'),
      },
      [AuthorizationStatus.Pending]: {
        color: '#F4F4F4',
        text: t('authorizationStatuses.pending'),
      },
    }),
    [t],
  );

  const steps: Record<CurrentSteps, { color: string; text: string }> = useMemo(
    () => ({
      [CurrentSteps.Discarded]: {
        color: '#F2C0BD',
        text: t('legends.discarded'),
      },
      [CurrentSteps.Review]: {
        color: '#FFE6A1',
        text: t('legends.inReview'),
      },
      [CurrentSteps.Approved]: {
        color: '#CEF0DA',
        text: t('legends.approved'),
      },
      [CurrentSteps.Hired]: {
        color: '#FFFFFF',
        text: t('legends.hired'),
      },
      [CurrentSteps.FileUpload]: {
        color: '#FFFFFF',
        text: t('legends.fileUpload'),
      },
      [CurrentSteps.Resume]: {
        color: '#FFFFFF',
        text: t('legends.resume'),
      },
      [CurrentSteps.Received]: {
        color: '#DDEFFF',
        text: t('legends.received'),
      },
    }),
    [t],
  );

  const columns = useMemo<ColumnTable<any>[]>(() => {
    return [
      {
        columnName: 'offer',
        headerText: t('offerName'),
        tdClassName: 'text-left',
        thClassName: 'text-left',
        cellFormat: ({ value }) => value?.name,
      },
      {
        columnName: 'createdAt',
        headerText: t('postulationDate'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      },
      {
        columnName: 'currentStep',
        headerText: t('postulationStatus'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <Badge
            color={steps[value as CurrentSteps].color}
            text={steps[value as CurrentSteps].text}
          />
        ),
      },
      {
        columnName: 'offer',
        headerText: t('offerStatus'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <Legend
            color={offerStatuses[value?.statusOffer?.name as OfferStatus].color}
            text={value?.statusOffer?.name}
          />
        ),
      },
      {
        columnName: 'authorizationStatus',
        headerText: t('authorizationStatus'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <Badge
            color={authorizationStatuses[value as AuthorizationStatus].color}
            text={authorizationStatuses[value as AuthorizationStatus].text}
          />
        ),
      },
      {
        columnName: 'action',
        headerText: t('action'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        // width: '150px',
        cellFormat: ({ row }) => {
          return (
            <div>
              <span onClick={() => goToPosulation(row.id)}>
                <Icon name="eye" size={18} />
              </span>
            </div>
          );
        },
      },
    ];
  }, [t, steps, offerStatuses, authorizationStatuses, goToPosulation]);

  return columns;
}
