import { addToast, ToastProps } from '@octano/global-ui';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row, Form } from 'reactstrap';

import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import BottomButtons from '../../../../components/steps/BottomButtons';
import HeaderSection from '../../../../components/steps/HeaderSection';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useStepState } from '../../../../hooks/useStepState';
import { fielesFormData, savefilesData } from '../../request';
import CertificateForm from './parts/CertificateForm';
import CurriculumForm from './parts/CurriculumForm';
import DegreeForm from './parts/DegreeForm';
import LicenseForm from './parts/LicenseForm';
import { valuesToSubmit } from './parts/utils';
import { File, FileFormField, FileTypes } from './types';

export default function Files() {
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const { postulationId } = useParams<{ postulationId: string }>();

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [profileCurriculum, setProfileCurriculum] = useState<File>();

  const methods = useForm<FileFormField>();
  const {
    reset,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const toast: Record<string, ToastProps> = useMemo(
    () => ({
      curriculumError: {
        icon: 'error',
        color: 'danger',
        text: t(`views.postulation.onSave.curriculumError`),
      },
      error: {
        icon: 'error',
        color: 'danger',
        text: t(`views.postulation.onSave.error`),
      },
      success: {
        icon: 'success',
        color: 'success',
        text: t(`views.postulation.onSave.success`),
      },
    }),
    [t],
  );

  const getData = useCallback(async () => {
    setLoading(true);
    const { data, error } = await fielesFormData(postulationId);
    if (error) {
      setErrorLoading(error.code);
    } else {
      const cv = data.files.find(
        (f) => f.reason === FileTypes.ProfileCurriculum,
      );
      const degrees = data.files.filter(
        (f) => f.reason !== FileTypes.ProfileCurriculum,
      );

      setProfileCurriculum(cv);
      reset({
        useApplicationCurriculum: data.useApplicationCurriculum ?? false,
        certifications: data.requiredCertificates.map((d: File) => {
          return {
            fileId: d.id,
            fileName: d.name,
            isSelected: d.isSelected ?? false,
            certificationId: d.certificationId,
            url: d.url,
          };
        }),
        licenses: data.requiredLicenseTypes.map((d: File) => {
          return {
            fileId: d.id,
            fileName: d.name,
            isSelected: d.isSelected ?? false,
            licenseTypeId: d.licenseTypeId,
            url: d.url,
          };
        }),
        collegeDegrees: degrees.map((d: File) => {
          return {
            fileId: d.id,
            fileName: d.name,
            isSelected: d.isSelected ?? false,
            url: d.url,
          };
        }),
      });
    }

    setLoading(false);
  }, [setLoading, postulationId, setErrorLoading, reset]);

  const onSubmit = useCallback(
    async (next = false) => {
      const values = getValues();
      const formData = valuesToSubmit(values);

      if (!profileCurriculum && !formData.useApplicationCurriculum && next) {
        addToast(toast.curriculumError);
        return;
      }

      const { certifications, collegeDegrees, licenses } = values;
      /**
       * si doy siguiente y al menos un certificado no es selecionado
       * o si no hay titulos celeccionado. no permito seguir
       */
      if (
        next &&
        (certifications.some((c) => !c.isSelected) ||
          licenses.some((l) => !l.isSelected) ||
          !collegeDegrees.some((c) => c.isSelected))
      ) {
        return;
      }

      const { error } = await savefilesData(parseInt(postulationId), formData);

      if (error) {
        addToast(toast.error);
      } else {
        addToast(toast.success);
        if (next) nextStep();
      }
    },
    [
      getValues,
      nextStep,
      postulationId,
      profileCurriculum,
      toast.curriculumError,
      toast.error,
      toast.success,
    ],
  );

  useEffect(() => {
    postulationId && getData();
  }, [getData, postulationId]);

  if (errorLoading) {
    return <DisplayError typeError={errorLoading} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <FormProvider {...methods}>
        <HeaderSection />
        <CurriculumForm profileCurriculum={profileCurriculum} />
        <DegreeForm />
        <CertificateForm />
        <LicenseForm />
        <Form onSubmit={handleSubmit(() => onSubmit(true))} className=" w-100">
          <BottomButtons
            onSave={() => onSubmit(false)}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </Form>
      </FormProvider>
    </Row>
  );
}
