import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface Parameters {
  [index: string]: string;
}

export default function useQuery() {
  const history = useHistory();
  const { pathname, search, state } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const currentParamsAsObject = useMemo(
    () => Object.fromEntries(query),
    [query],
  );

  const getParam = useCallback(
    (name: string) => query.get(name) || undefined,
    [query],
  );

  const setParams = useCallback(
    (newParams: Parameters) => {
      const url = pathname;
      const currentState = state;

      const params = new URLSearchParams(newParams).toString();
      history.push(`${url}?${params}`, currentState);
    },
    [history, pathname, state],
  );

  return { query, getParam, setParams, currentParamsAsObject };
}
