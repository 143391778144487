import axios from 'axios';

export const baseURL =
  process.env.REACT_APP_API_URL || 'http://localhost:3000/application-portal';

const API = axios.create({
  baseURL,
});

export default API;
