import {
  BaseQueryFn,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query';
import { JWT_KEY } from '../config/constants';
import { UserSessionEvent } from '../types/auth';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

/**
 * Función para guardar el token del usuario
 * @param token
 */
export const setJWT = (token: string) => localStorage.setItem(JWT_KEY, token);

/**
 * Función para obtener el token del usuario
 */
export const getJWT = () => localStorage.getItem(JWT_KEY);

/**
 * Función para remover el token del usuario
 */
export const removeJWT = () => localStorage.removeItem(JWT_KEY);

/**
 * Función para obtener la data del JWT
 */
export const parseJWT = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

/**
 * Función que determina si un usuario cumple con algo de los permisos requeridos. Para evaluar el cumplimiento de todos los permisos a la vez, usar allPermisionsRequired = true
 * @param userPermissions lista con los permisos que posee el usuario
 * @param permissionsToCheck lista con los permisos que se desean chequear
 * @param allPermisionsRequired En caso de ser true evaluará los requisitos como un AND y no como un OR
 * @returns boolean
 */
export const isUserAuthorizedTo = (
  userPermissions: string[],
  permissionsToCheck: string[],
  allPermisionsRequired?: boolean,
) => {
  if (!permissionsToCheck?.length) return true;

  let matchedCount = 0;

  permissionsToCheck.forEach((permission) => {
    const match = userPermissions.some((userP) => userP === permission);
    if (match) matchedCount++;
  });

  return allPermisionsRequired
    ? matchedCount === permissionsToCheck.length
    : matchedCount > 0;
};

/**
 * Función para obtener la base url para el api
 */
const getBaseUrl = (scope?: string): string =>
  (process.env.REACT_APP_API_URL || 'http://localhost:8000') +
  `${scope?.trim() || ''}`.trim();

/**
 * Función para obtener la baseQuery para RTK (redux tool kit)
 */
export const getBaseQuery = (scope?: string) =>
  fetchBaseQuery({
    baseUrl: getBaseUrl(scope),
    prepareHeaders: (headers) => {
      const accessToken = getJWT();

      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  });

type BaseQueryFnAxios = {
  url: string;
  method?: AxiosRequestConfig['method'];
  body?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
};

type BaseQueryFnAxiosResponse = BaseQueryFn<
  BaseQueryFnAxios,
  unknown,
  FetchBaseQueryError
>;

/**
 * Función para obtener la baseQuery (con axios) para RTK (redux tool kit)
 */
export const axiosBaseQuery =
  (scope?: string): BaseQueryFnAxiosResponse =>
  async ({ url, method, body, params }) => {
    try {
      const response = await axios({
        url: getBaseUrl(scope) + url,
        method,
        data: body,
        params,
        headers: {
          Authorization: `Bearer ${getJWT()}`,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      return { data: response?.data };
    } catch (_error: any) {
      throw new AxiosError(
        _error?.response?.data || _error?.message,
        _error?.response?.status,
      );
    }
  };

/**
 * Función para manejar el login expired error (redux tool kit)
 */
export const handleSessionExpiredError = <T extends FetchBaseQueryError>(
  response: T,
): T => {
  if (response?.status === 401) {
    const sessionExpiredEvent = new CustomEvent(UserSessionEvent.EXPIRED);
    window.dispatchEvent(sessionExpiredEvent);
  }
  return response;
};
