import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DisplayInfo, TablePagination, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col } from 'reactstrap';
import clsx from 'clsx';
import JobCard from './JobCard';

import Loading from '../../../components/info/Loading';
import DisplayError from '../../../components/info/DisplayError';
import { OfferDetail } from '../../../types/jobOffer';
import { getOffersList } from '../../../api/requests/offer';
import { useLoadingState } from '../../../hooks/useLoadingState';
import useQuery from '../../../hooks/customQuery';

const ITEMS_PER_PAGE = 10;

const JobList = () => {
  const prefix = 'views.jobsList';
  const isMobile = useMobile();
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const URLSearchParams = useQuery();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const [offerList, setOfferList] = useState<OfferDetail[]>([]);

  const getData = useCallback(
    async (
      page: string | null,
      search: string | null,
      workingDay: string | null,
      campus: string | null,
      career: string | null,
    ) => {
      setLoading(true);
      const { data, error } = await getOffersList({
        itemsPerPage: ITEMS_PER_PAGE,
        page: page ? parseInt(page) - 1 : 0,
        search: search ? search : '',
        workingDay: workingDay ? parseInt(workingDay) : 0,
        campus: campus ? parseInt(campus) : 0,
        career: career ? parseInt(career) : 0,
      });
      setCurrentPage(page ? parseInt(page) : 0);
      if (error) {
        setErrorLoading(error.code);
      }
      if (data) {
        setErrorLoading(undefined);
        setOfferList(data.data);
        setTotalPages(data.totalPages);
        setTotalOffers(data.total);
      }
      setLoading(false);
      URLSearchParams.set('page', page ? page : '1');
      history.push({
        pathname: '/public/jobs',
        search: URLSearchParams.toString(),
      });
    },
    [URLSearchParams, history, setErrorLoading, setLoading],
  );

  useEffect(() => {
    getData(
      URLSearchParams.get('page'),
      URLSearchParams.get('keyWord'),
      URLSearchParams.get('workingDay'),
      URLSearchParams.get('campus'),
      URLSearchParams.get('career'),
    );
  }, [getData, URLSearchParams]);

  if (errorLoading) {
    return (
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <DisplayError typeError={errorLoading} />
      </Card>
    );
  }
  if (loading) {
    return (
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <Loading />
      </Card>
    );
  }
  if (offerList.length === 0) {
    return (
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <div className="d-flex flex-column align-items-center no-result">
          <DisplayInfo
            title={t(`common.emptyState.title`)}
            textBody={t(`common.emptyState.subtitle`)}
          />
        </div>
      </Card>
    );
  }
  return (
    <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
      <Row>
        <Col xs={12}>
          <h1 className="fw-700 fs-20 text-primary mb-4">
            {t(`${prefix}.jobTitle`)}
          </h1>
        </Col>
      </Row>
      {offerList.map((offer, index) => {
        return (
          <Row className="mb-3" key={index}>
            <Col xs={12} className="jobCard">
              <JobCard {...{ offer }} />
            </Col>
          </Row>
        );
      })}
      <TablePagination
        pagination={{
          currentPage: currentPage,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: totalOffers,
          totalPages: totalPages,
          onChangePage: (page) => {
            URLSearchParams.set('page', page.toString());
            history.push({
              pathname: '/public/jobs',
              search: URLSearchParams.toString(),
            });
          },
        }}
      />
    </Card>
  );
};

export default JobList;
