import { Button, Modal, useMobile } from '@octano/global-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AccountContainer from '../shared/AccountContainer';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
}

export default function SuccessModal({
  title,
  description,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Modal isOpen={isOpen} toggle={() => false}>
      <AccountContainer
        title={title}
        subTitle={description}
        icon="success"
        showLogo={false}
      >
        <Button
          type="button"
          text={t(`common.actions.understood`)}
          onClick={onClose}
          className="mt-4 m-auto"
          style={{ maxWidth: isMobile ? '100%' : '283.7px' }}
          fullwidth
        />
      </AccountContainer>
    </Modal>
  );
}
