import {
  Account,
  LoginField,
  LoginResponse,
  PasswordRecoveryFields,
  ActivateAccount,
} from '../../types/auth';
import { AUTH } from '../endpoints';
import request from '../request';

/**
 * Envía una solicitud al servidor para verificar si el token de activación es válido.
 * @param {string} email - La dirección de correo electrónico del usuario
 * @returns Una promesa que se resuelve en un objeto Cuenta.
 */
export const sendActivationEmail = (email: string) => {
  return request<Account>(AUTH.SEND_ACTIVATION_EMAIL, {
    method: 'POST',
    data: { email },
  });
};

/**
 * Envía una solicitud al servidor para verificar si el token de activación es válido
 * @param {string} email - La dirección de correo electrónico del usuario
 * @param {string} token - El token que se envió al correo electrónico del usuario.
 * @returns El tipo de devolución es una promesa que se resuelve en un objeto Cuenta.
 */
export const checkActivationToken = (email: string, token: string) => {
  return request<Account>(AUTH.CHECK_ACTIVATION_TOKEN, {
    params: { email, token },
  });
};

/**
 * actualiza los datos de usuario de una cuenta existente
 */
export const activateAccount = (data: ActivateAccount) => {
  const url = AUTH.ACTIVATE_ACCOUNT;
  return request<Account>(url, {
    method: 'PUT',
    data,
  });
};

/**
 * Envia correo de recuperación de contraseña
 */
export const sendPasswordRecoveryEmail = (email: string) => {
  const url = AUTH.SEND_PASSWORD_RECOVERY_MAIL;
  return request<any>(url, {
    method: 'POST',
    data: { email },
  });
};

/**
 * cambia la contraseña de usuario usando el token recibido en el
 * correo de recuperación de contraseña
 */
export const passwordRecovery = (data: PasswordRecoveryFields) => {
  return request<any>(AUTH.PASSWORD_CHANGE, {
    method: 'POST',
    data,
  });
};

/**
 * Inicio de sesión
 */
export const accountLogin = (data: LoginField) => {
  return request<LoginResponse>(AUTH.LOGIN, {
    method: 'POST',
    data,
  });
};

/**
 * Valida el token de recuperación de contraseña
 */
export const validateRecoveryToken = (email: string, token: string) => {
  return request<Account>(AUTH.VALIDATE_RECOERY_TOKEN, {
    params: { email, token },
  });
};
