import { EducationalInformation } from '../views/postulation/steps/resume/types';
import { File } from '../views/postulations/types';
import { Commune, Country } from './registrationTypes';

export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}

export interface Account {
  fullName: string;
  email: string;
}

export interface Permission {
  id: number;
  name_public: string;
  name: PermissionName;
}

export enum PermissionName {
  exampleEdit = 'example.edit',
  exampleDelete = 'example.delete',
}

export enum Scope {
  Maintainers = 'maintainers',
  Postulant = 'postulant',
}

export interface UserData extends Account {
  permissions?: Permission['name'][];
  scope: Scope;
}

export interface Account {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthday: string;
  lastLogin: string;
  isActive: boolean;
  roles: any;
  fullName: string;
}

export enum Genders {
  Male = 'Femenino',
  Female = 'Masculino',
  nonbinary = 'No binario',
  Unspecified = 'No especificar',
}

export interface UserProfile {
  id: number;
  linkedin: string | null;
  gender?: Genders | null;
  professionalResume: string;
  profession: string | null;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string | null;
  communeId: number;
  commune?: Commune | null;
}

export interface User {
  id: number;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  rut?: string | null;
  passport?: string | null;
  birthday?: string | null;
  email: string;
  phone: string;
  countryId?: string | null;
  isPostulant?: boolean | null;
  country?: Country | null;
  profile?: UserProfile | null;
  educationalInformation?: EducationalInformation[];
  files?: File[];
}

export interface LoginField {
  email: string;
  password: string;
}

export interface AccountValidationFields {
  email: string;
}

export interface UpdateAccountFormField {
  phone: string;
  rut: string;
  password: string;
  passwordConfirm: string;
}

export interface ActivateAccount {
  token: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface AccountRecoveryFields {
  email: string;
}

export interface PasswordRecoveryFormFields {
  password: string;
  passwordConfirm: string;
}

export interface PasswordRecoveryFields {
  token: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface TokenStatus {
  expired: boolean;
}

export interface LoginResponse {
  access: {
    token: string;
  };
  account: Account;
  permissions: Permission[];
}
