const withBasicProvider =
  (Provider: (props: any) => JSX.Element) =>
  (Component: (props: any) => JSX.Element) =>
  () =>
    (
      <Provider>
        <Component />
      </Provider>
    );
export default withBasicProvider;

/**
 * Ejemplo de uso: export default withBasicProvider(CounterProvider)(Component);
 */
