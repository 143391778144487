import { addToast, Button, Modal, TextInput } from '@octano/global-ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { sendPasswordRecoveryEmail } from '../../../api/requests/auth';
import { useValidations } from '../../../hooks/useValidations';
import { AccountRecoveryFields } from '../../../types/auth';
import AccountContainer from '../shared/AccountContainer';

interface Props {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export default function PasswordRecoveryModal({
  isOpen,
  onSuccess,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const prefix = `views.account.recovery`;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<AccountRecoveryFields>({ mode: 'onChange' });

  const { validateTextNotEmpty, validateEmail } = useValidations();

  const handleSubmitRecovery = async ({ email }: AccountRecoveryFields) => {
    const { error } = await sendPasswordRecoveryEmail(email);

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    } else {
      onSuccess();
    }
    reset({ email: undefined });
  };

  return (
    <Modal isOpen={isOpen} toggle={() => false}>
      <AccountContainer
        icon="mailing"
        title={t(`${prefix}.title`)}
        subTitle={t(`${prefix}.description`)}
        showLogo={false}
      >
        <Col xs={12} className="pt-2">
          <form onSubmit={handleSubmit(handleSubmitRecovery)}>
            <Row>
              <Col xs={12} className="mb-2">
                <TextInput
                  name="email"
                  type="text"
                  control={control}
                  label={t('common.terms.email')}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      email: validateEmail,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} className="mb-4">
                <Button
                  type="button"
                  text={t(`common.actions.cancel`)}
                  onClick={onClose}
                  fullwidth
                  outlined
                />
              </Col>
              <Col sm={12} md={6}>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text={t(`common.actions.recover`)}
                  fullwidth
                />
              </Col>
            </Row>
          </form>
        </Col>
      </AccountContainer>
    </Modal>
  );
}
