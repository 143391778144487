import { ToastProvider } from '@octano/global-ui';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AppVersion from './components/AppVersion/AppVersion';
import routes from './config/routes';
import UserStateProvider from './providers/UserStateProvider';
import { Provider } from 'react-redux';
import { store } from './store/index';

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <UserStateProvider>
          <AppVersion />
          <Router>
            <Switch>
              {Object.entries(routes)?.map(([key, value]) => {
                const Layout = value.layoutComponent;
                return (
                  <Route
                    key={key}
                    path={key}
                    render={(props) => <Layout {...props} />}
                  />
                );
              })}
              <Redirect from="*" to="/public" />
            </Switch>
          </Router>
        </UserStateProvider>
      </ToastProvider>
    </Provider>
  );
}

export default App;
