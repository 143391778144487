import { Col } from 'reactstrap';
import { Answer } from '../../views/postulation/steps/review/types';

interface Props {
  title: string;
  subtitle?: string | null;
  description?: string | string[] | null;
  answer?: Answer[];
  isVisible?: boolean;
}

export default function Section({
  title,
  subtitle,
  description,
  answer,
  isVisible = true,
}: Props) {
  if (!isVisible) {
    return null;
  }

  return (
    <Col xs={12} className="mb-4">
      <p className="text-primary fs-20 fw-700 mb-3 text-uppercase">{title}</p>
      {Boolean(subtitle) && (
        <p className="text-light fs-16 fw-700 mb-3 text-uppercase">
          {subtitle?.trim()}
        </p>
      )}
      {description && Array.isArray(description) ? (
        description.map((d, i) => (
          <p key={`desc-${i}`} className="fs-16">
            {d}
          </p>
        ))
      ) : (
        <p className="fs-16">{description}</p>
      )}
      {answer &&
        answer.map((d, i) => (
          <div key={`q-${i}`}>
            <p className="fs-20 fw-600">{d.question}</p>
            <p className="fs-16">{d.answer}</p>
          </div>
        ))}
    </Col>
  );
}
