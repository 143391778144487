import { UserData } from '../types/auth';

/**
 * Si el objeto userData no tiene una propiedad de permisos, entonces el usuario es un postulante.
 * @param {UserData} userData - UserData
 * @returns Una función que toma un objeto userData y devuelve un valor booleano.
 */
export const isPostulant = (userData: UserData) => {
  return !userData.permissions;
};
