import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import routes, { PathsLayouts } from "../../config/routes";

/**
 * Es utilizado para renderizar todas las rutas asociadas a un layout
 */
const RoutesByLayout = (): JSX.Element | null => {
  const { path: matchPath } = useRouteMatch();
  const routesByLayout = routes[matchPath as PathsLayouts];

  if (routesByLayout) {
    return (
      <Switch>
        {routesByLayout.views.map(({ path, component }, key) => {
          return (
            <Route
              path={matchPath + path}
              component={component}
              key={key}
              exact
            />
          );
        })}

        <Redirect from="*" to={matchPath + routesByLayout.defaultPath} />
      </Switch>
    );
  }

  return null;
};

export default RoutesByLayout;
