import { Base } from './GenericFormTypes';

interface Campus extends Base {
  email: string;
}

export enum OfferStatus {
  // available = vigente
  Available = 1,
  InProgress = 3,
  Closed = 4,
  Finished = 5,
}

interface ContractType extends Base {}
interface WorkingDay extends Base {}
interface Modality extends Base {}
interface SpecialtyArea extends Base {}
interface Career extends Base {}
interface Subject extends Base {}
interface School extends Base {}
interface Period extends Base {}

export interface StatusOffer extends Base {
  name: string;
}
export interface ApplicationStage extends Base {}
export interface Education extends Base {}
export interface LanguageLevel extends Base {}
export interface Language extends Base {}
export interface Certification extends Base {}
export interface Requirement extends Base {}
export interface Competence extends Base {}
export interface Benefit extends Base {}
export interface Law extends Base {}
interface DriverLicense extends Base {}
export interface OfferLanguageLevel {
  language: Language;
  languageLevel: LanguageLevel;
}

export interface LicenseRequirements extends Base {
  driverLicense: DriverLicense;
}

interface Schedule extends Base {}

export enum OfferType {
  Private = 'private',
  Public = 'public',
}

export interface OfferDetail {
  id: number;
  name: string;
  vacant?: string;
  contract_period?: string;
  salary?: string;
  description: string;
  levelResponsibility?: string;
  job_function?: string;
  dateInit?: string;
  updatedAt: string;
  createdAt: string;
  school: School;
  subjectHours: number;
  specialtyArea?: SpecialtyArea;
  period: Period;
  career: Career;
  campus: Campus;
  workingDay: WorkingDay;
  modality: Modality;
  subject: Subject;
  statusOffer: StatusOffer;
  curriculum?: string;
  knowledge: string;
  applicationStages: ApplicationStage[];
  educations: Education[];
  offerLanguageLevels: OfferLanguageLevel[];
  certifications: Certification[];
  type?: OfferType;
  licenseTypes: any[];
  requirements: Requirement[];
  licenseRequirements: LicenseRequirements[];
  competencies: Competence[];
  benefits: Benefit[];
  laws: Law[];
  draftFlowId: number;
  hasApplication?: boolean;
}

export interface OfferCreationOptions {
  benefits: Benefit[];
  laws: Law[];
  campus: Campus[];
  contractTypes: ContractType[];
  modalities: Modality[];
  schedule: Schedule[];
  workingDays: WorkingDay[];
  specialtyAreas: SpecialtyArea[];
  subjects: Subject[];
  schools: School[];
}

export interface PosibleOfferStateList {
  icon: 'eye' | 'error' | 'success' | 'warning' | 'waiting';
  id: number;
  name: string;
}

export interface UpdateOfferStateType {
  statusId: number;
}
