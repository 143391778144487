import { addToast, Button, ToastProps, useMobile } from '@octano/global-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Form, Col, Container } from 'reactstrap';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import HeaderSection from '../../../../components/steps/HeaderSection';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { review, saveReview } from '../../request';
import File from './parts/File';

import { ReviewRequest } from './types';
import Section from '../../../../components/Section/Section';

export default function Review() {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMobile();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const { postulationId, offerId } = useParams<{
    postulationId: string;
    offerId: string;
  }>();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [data, setData] = useState<ReviewRequest>({
    educationalLevel: [],
    answer: [],
    laws: [],
    certifications: [],
    curriculum: [],
    collegeDegrees: [],
    licenses: [],
    availabilityDate: '',
    availabilityTime: '',
    salaryPretension: 'No establecida',
    hasAdministrativeInabilities: false,
    hasInstitutionalInabilities: false,
  });

  const toast: Record<string, ToastProps> = useMemo(
    () => ({
      error: {
        icon: 'error',
        color: 'danger',
        text: t(`views.postulation.review.onSave.error`),
      },
      success: {
        icon: 'success',
        color: 'success',
        text: t(`views.postulation.review.onSave.success`),
      },
    }),
    [t],
  );

  const getData = useCallback(async () => {
    if (postulationId) {
      setLoading(true);
      const { data, error } = await review(postulationId);
      if (error) {
        setErrorLoading(error.code);
      } else {
        setData(data);
      }

      setLoading(false);
    }
  }, [setLoading, postulationId, setErrorLoading]);

  const onSubmit = useCallback(async () => {
    const { error } = await saveReview(postulationId);

    if (error) {
      addToast(toast.error);
    } else {
      addToast(toast.success);
      history.push({ pathname: `/jobs/detail/${offerId}` });
    }
  }, [history, offerId, postulationId, toast]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (errorLoading) {
    return <DisplayError typeError={errorLoading} />;
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <HeaderSection />
        <Col xs={12} className="mb-4">
          <p className="text-primary fs-30 fw-700 mb-1 text-uppercase">
            {t('views.postulation.review.summary')}
          </p>
        </Col>
        <Section
          title={t('views.postulation.review.educationalLevel')}
          description={data.educationalLevel}
        />
        <Section
          title={t('views.postulation.review.salaryPretension')}
          description={data.salaryPretension}
          isVisible={Boolean(data.salaryPretension)}
        />
        <Section
          title={t('views.postulation.review.startAvailability')}
          description={data.availabilityDate}
          isVisible={typeof data.availabilityDate === 'string'}
        />
        <Section
          title={t('views.postulation.review.scheduleAvailability')}
          description={data.availabilityTime}
          isVisible={typeof data.availabilityTime === 'string'}
        />
        <Section
          title={t('views.postulations.review.hasAdministrativeInabilities')}
          subtitle={t(
            'views.postulations.review.hasAdministrativeInabilitiesSubtitle',
          )}
          description={
            data?.hasAdministrativeInabilities
              ? t('views.postulations.review.yes')
              : t('views.postulations.review.no')
          }
          isVisible={typeof data?.hasAdministrativeInabilities === 'boolean'}
        />
        <Section
          title={t('views.postulations.review.hasInstitutionalInabilities')}
          subtitle={t(
            'views.postulations.review.hasInstitutionalInabilitiesSubtitle',
          )}
          description={
            data?.hasInstitutionalInabilities
              ? t('views.postulations.review.yes')
              : t('views.postulations.review.no')
          }
          isVisible={typeof data?.hasInstitutionalInabilities === 'boolean'}
        />
        <Section
          title={t('views.postulation.review.questions')}
          answer={data.answer}
          isVisible={data.answer.length > 0}
        />
        {data.laws?.map((l, i) => (
          <Section
            key={i}
            title={l.name}
            subtitle={l.question}
            description={
              l.answer
                ? 'Confirmo haber leído y estar de acuerdo.'
                : 'No confirmo haber leído y estar de acuerdo.'
            }
          />
        ))}
        {data.curriculum.length > 0 && (
          <Col xs={12} sm={7} className="mb-4">
            <File
              name={data.curriculum[0].name}
              url={data.curriculum[0].url}
              documentName={'CURRICULUM'}
            />
          </Col>
        )}
        {data.collegeDegrees.length > 0 && (
          <>
            <Col xs={12} sm={7}>
              <p className="text-primary fs-20 fw-700 mb-3 text-uppercase">
                {t('views.postulation.review.collegeDegrees')}
              </p>
            </Col>
            {data.collegeDegrees.map((c, i) => (
              <Col key={i} xs={12} sm={7} className="mb-4">
                <File
                  name={c.name}
                  url={c.url}
                  documentName={c.documentName!}
                />
              </Col>
            ))}
          </>
        )}

        {data.certifications?.map((c, i) => (
          <Col key={i} xs={12} sm={7} className="mb-4">
            <File name={c.name} url={c.url} documentName={c.documentName!} />
          </Col>
        ))}

        {data.licenses?.map((l, i) => (
          <Col key={i} xs={12} sm={7} className="mb-4">
            <File name={l.name} url={l.url} documentName={l.documentName!} />
          </Col>
        ))}

        <Container fluid>
          <Row>
            <Col className="text-right mt-5">
              <Button
                type="submit"
                text={t(`views.postulation.review.confirm`)}
                style={{ width: isMobile ? '100%' : '256px' }}
                loading={isSubmitting}
                disabled={isSubmitting}
                className="mb-3"
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </Form>
  );
}
