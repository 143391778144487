// Components
import { Button, FileInputControlled, Icon } from '@octano/global-ui';
import { Col, UncontrolledTooltip } from 'reactstrap';

// Hooks
import { useValidations } from '../../../../hooks/useValidations';
import { Control, UseFormWatch } from 'react-hook-form';
import { useRef } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { RegisterFormFields } from '../types';

interface Props {
  control: Control<RegisterFormFields>;
  watch: UseFormWatch<RegisterFormFields>;
  isSubmitting: boolean;
  downloadEnabled?: boolean;
}

// Render
export default function FileUpload({
  control,
  watch,
  isSubmitting,
  downloadEnabled = false,
}: Props) {
  const { t } = useTranslation();
  const cvRef = useRef(null);
  const additionalRef = useRef(null);
  const { validateTextNotEmpty, validateFileSize } = useValidations();

  const [curriculum, additionalFile] = watch(['curriculum', 'additionalFile']);

  const downloadFile = (url: string) => {
    window?.open(url, '_blank')?.focus();
  };

  return (
    <>
      <Col xs={12} className="pt-3">
        <p className="fs-20 fw-700 text-primary">
          {t('views.account.registration.form.curriculum')}
          <span ref={cvRef} className="ml-1">
            <Icon name="information" className="mb-1" />
          </span>
        </p>
      </Col>
      <Col className="d-flex align-items-end" xs={12} md={6}>
        <div className="flex-fill">
          <FileInputControlled
            label=""
            name="curriculum"
            btnText={t('views.account.registration.form.addCurriculum')}
            control={control}
            accept="image/png, image/jpeg, application/pdf"
            disabled={isSubmitting}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                fileSize: validateFileSize,
              },
            }}
          />
        </div>
        {!!(downloadEnabled && (curriculum as any)?.url) && (
          <Button
            style={{ width: 60, paddingLeft: 'calc(20px + 0.5rem)' }}
            className="mb-1 ml-2"
            outlined
            size="md"
            icon="download"
            onClick={() => downloadFile((curriculum as any)?.url)}
          />
        )}
      </Col>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary mt-3">
          {t('views.account.registration.form.additionalFile')}
          <span ref={additionalRef} className="ml-1">
            <Icon name="information" className="mb-1" />
          </span>
        </p>
      </Col>
      <Col className="d-flex align-items-end" xs={12} md={6}>
        <div className="flex-fill">
          <FileInputControlled
            label=""
            btnText={t('views.account.registration.form.addOtherFile')}
            name="additionalFile"
            accept="image/png, image/jpeg, application/pdf"
            disabled={isSubmitting}
            control={control}
            rules={{
              validate: {
                fileSize: validateFileSize,
              },
            }}
          />
        </div>
        {!!(downloadEnabled && (additionalFile as any)?.url) && (
          <Button
            style={{ width: 60, paddingLeft: 'calc(20px + 0.5rem)' }}
            className="mb-1 ml-2"
            outlined
            size="md"
            icon="download"
            onClick={() => downloadFile((additionalFile as any)?.url)}
          />
        )}
      </Col>
      <UncontrolledTooltip placement="right" target={cvRef}>
        {t('views.account.registration.form.fileMaxSize')}
      </UncontrolledTooltip>

      <UncontrolledTooltip placement="right" target={additionalRef}>
        {t('views.account.registration.form.fileMaxSize')}
      </UncontrolledTooltip>
    </>
  );
}
