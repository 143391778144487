import { Button, Icon } from '@octano/global-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

type DisplayErrorProps = {
  typeError: 'CONNECTION' | 'HTTP_ERROR' | string;
  title?: string;
  body?: string;
  buttonText?: string;
  retryAction?: () => void;
  loadingAction?: boolean;
  insideCard?: boolean;
};

/**
 * Componente utilizada para mostrar mensaje de error al cargar información.
 * Se puede agregar botón con acción para reintentar la carga.
 * Titulo por defecto es "No se pudo cargar la información",
 */
const DisplayError = ({
  title,
  body,
  typeError,
  buttonText,
  retryAction,
  loadingAction = false,
  insideCard = false,
}: DisplayErrorProps) => {
  const prefix = 'common.displayError';
  const { t } = useTranslation();

  const getBody = () => {
    if (typeError === 'FETCH_ERROR') {
      return t(`${prefix}.errorConnection`);
    } else if (`${typeError}` === '500') {
      return t(`${prefix}.errorUnexpected`);
    } else if (typeError === 'CONNECTION') {
      return t(`${prefix}.errorConnection`);
    } else if (typeError === 'HTTP_ERROR') {
      return t(`${prefix}.errorUnexpected`);
    }
    return typeError;
  };

  const Content = () => (
    <div style={{ alignSelf: 'center' }}>
      <Row className="text-center py-4 px-3">
        <Col xs={12}>
          <Icon name="warning" color="secondary" size="65px" />
        </Col>
        <Col xs={12} className="py-4">
          <span className="fs-20 text-dark">
            {title || t(`${prefix}.title`)}
          </span>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300 text-medium">
            <Trans t={t}>{body || getBody()}</Trans>
          </p>
        </Col>
        {retryAction && (
          <Col xs={12} className="py-3 px-1 px-md-3">
            <Button
              text={buttonText || t(`${prefix}.retryBtn`)}
              onClick={retryAction}
              loading={loadingAction}
              fullwidth
            />
          </Col>
        )}
      </Row>
    </div>
  );

  if (insideCard) {
    return (
      <Card className="py-4">
        <Content />
      </Card>
    );
  } else {
    return <Content />;
  }
};

export default DisplayError;
