import dayjs from 'dayjs';

import { Base } from '../../../../../types/GenericFormTypes';
import {
  EducationalInformation,
  EducationalInformationRequest,
  ResumeFormField,
  ResumRequest,
} from '../types';

export function mapResumeItems<T = Base>(arr: T[], property: string): string {
  return arr
    .map((item: any) => {
      return item[property];
    })
    .join(', ');
}

export function mapLanguageItems<T = Base>(
  property: string,
  arr?: T[],
): string {
  if (!arr) return '';

  return arr
    .map(({ language, languageLevel }: any) => {
      return `${language[property]} - ${languageLevel[property]}`;
    })
    .join(', ');
}

/**
 * Toma un objeto ResumRequest y devuelve un objeto ResumForm
 * Los valores retornados son usados para establecer la data
 * por defecto del formulario
 * @param {ResumRequest} data - Solicitud de resumen
 * @returns valores por defecto del formulario
 */
export function defaultValuesFromRequest(data: ResumRequest) {
  return {
    immediateInitialAvailabilityTime: data.immediateInitialAvailabilityTime,
    immediateInitialAvailabilityDate: data.immediateInitialAvailabilityDate,
    initialAvailabilityDate: data.initialAvailabilityDate,
    finalAvailabilityDate: data.finalAvailabilityDate,
    initialAvailabilityTime: data.initialAvailabilityTime
      ? dayjs(data.initialAvailabilityTime).utc().format('HH:mm')
      : undefined,
    finalAvailabilityTime: data.finalAvailabilityTime
      ? dayjs(data.finalAvailabilityTime).utc().format('HH:mm')
      : undefined,
    hasAdministrativeInabilities:
      typeof data.hasAdministrativeInabilities === 'boolean'
        ? String(data.hasAdministrativeInabilities)
        : undefined,
    hasInstitutionalInabilities:
      typeof data.hasInstitutionalInabilities === 'boolean'
        ? String(data.hasInstitutionalInabilities)
        : undefined,

    salaryPretension: data.salaryPretension,
    educationalInformation: [],
    /**
     * Lista Las preguntas de una oferta.
     * los valores despues del ?? operador se usan cuando ya se han gurdado preguntas
     * debido a que se envia un objeto distinto
     */
    postulationAnswer: data.questions.map((q) => {
      return {
        name: q.name ?? q.question.name,
        questionId: q.id ?? q.questionId,
        answer: q.answer,
      };
    }),
    postulationLaws: data.laws.map((q) => {
      return {
        name: q.name ?? q.law.name,
        lawId: q.id ?? q.lawId,
        isSelected: Boolean(q.isSelected),
        ask: q.ask ?? q.law.ask,
        explanation: q.explanation ?? q.law.explanation,
      };
    }),
    /**
     * Lista los lenguajes de una oferta en el formulario de resumen
     */
    postulationLanguage: data.offerLanguageLevels?.map((l) => {
      return {
        languageId: l.language.id,
        languageLevelId: l.languageLevel.id,
        languageName: l.language.name,
        levelName: l.languageLevel.name,
        isSelected: Boolean(l.isSelected),
      };
    }),
  };
}

/**
 * Prepara la data que será enviada en en formulario
 */
export function valuesToSubmit(values: ResumeFormField) {
  const check = (str?: string) =>
    typeof str === 'string' ? /true/i.test(str) : undefined;

  return {
    ...values,
    hasAdministrativeInabilities: check(values?.hasAdministrativeInabilities),
    hasInstitutionalInabilities: check(values?.hasInstitutionalInabilities),
    educationalInformation: values.educationalInformation.map(
      ({ collegeDegree, college, educationalLevel }) => {
        return {
          collegeDegreeId: collegeDegree!.value,
          educationalLevelId: educationalLevel!.value,
          college: college,
        };
      },
    ),
    salaryPretension: parseInt(values.salaryPretension as string),
  };
}

/**
 * Toma la información educacional del formulario cuando es enviado
 * y transforma esta data para ser mostrada en el listado de niveles educacionales
 */

export function simulateEducationalInformationRequest(
  arr: EducationalInformation[],
) {
  return arr.map((e) => {
    return {
      collegeDegree: {
        id: e.collegeDegree?.value,
        name: e.collegeDegree?.label,
      },
      educationalLevel: {
        id: e.educationalLevel?.value,
        name: e.educationalLevel?.label,
      },
      college: e.college,
    } as EducationalInformationRequest;
  });
}
