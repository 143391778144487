import { CheckInput } from '@octano/global-ui';
import React, { Fragment, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

import { ResumeFormField } from '../types';

export default function Languages() {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<ResumeFormField>();

  const { fields } = useFieldArray({
    control,
    name: 'postulationLanguage',
  });

  const postulationLanguage = watch('postulationLanguage');

  const someSelected = useMemo(
    () => postulationLanguage.some((p) => p.isSelected),
    [postulationLanguage],
  );

  const languagesList = useMemo(
    () =>
      fields.map((item, i) => (
        <Fragment key={item.id}>
          <Col xs={12} className="mb-4">
            <CheckInput
              label={`${item.languageName} - ${item.levelName}`}
              name={`postulationLanguage.${i}.isSelected` as const}
              control={control}
              rules={{
                validate: {
                  conditional: () => {
                    if (!someSelected) {
                      return t('common.validations.required');
                    }
                    return undefined;
                  },
                },
              }}
            />
          </Col>
        </Fragment>
      )),
    [control, fields, someSelected, t],
  );

  return (
    <>
      {fields.length > 0 && (
        <>
          <Col xs={12}>
            <p className="fs-20 fw-700 text-primary">IDIOMAS</p>
          </Col>
          {languagesList}
        </>
      )}
    </>
  );
}
