import { CheckInput, Icon, TimeInput } from '@octano/global-ui';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import { ResumeFormField } from '../types';

interface Props {
  isVisible: boolean;
}

const keyPrefix = 'views.postulation.resume.availabilitySection';

export default function Availability({ isVisible }: Props) {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<ResumeFormField>();

  const scheduleRef = useRef(null);

  const [time] = watch(['immediateInitialAvailabilityTime']);

  useEffect(() => {
    if (time) {
      setValue('initialAvailabilityTime', undefined);
      setValue('finalAvailabilityTime', undefined);
    }
  }, [time, setValue]);

  const conditional = (canValidate?: any) => (fieldValue?: any) => {
    if (!canValidate && !fieldValue) {
      return t('common.validations.required');
    }
    return undefined;
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col xs={12}>
          <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
            {t(`${keyPrefix}.scheduleAvailability`)}
            <span ref={scheduleRef} className="ml-1">
              <Icon name="information" className="mb-1" />
            </span>
          </h5>
        </Col>

        <Col xs={12}>
          <CheckInput
            label={t(`${keyPrefix}.immediateInitialAvailabilityTime`)}
            name="immediateInitialAvailabilityTime"
            control={control}
          />
        </Col>

        <Col xs={12} md={4}>
          <TimeInput
            id="1"
            label={t(`${keyPrefix}.initialAvailabilityTime`)}
            name="initialAvailabilityTime"
            control={control}
            rules={{
              validate: {
                conditional: conditional(time),
              },
            }}
            disabled={time}
          />
        </Col>
        <Col xs={12} md={4}>
          <TimeInput
            id="2"
            label={t(`${keyPrefix}.finalAvailabilityTime`)}
            name="finalAvailabilityTime"
            control={control}
            rules={{
              validate: {
                conditional: conditional(time),
              },
            }}
            disabled={time}
          />
        </Col>

        {/*
            <UncontrolledTooltip placement="right" target={scheduleRef}>
              Hello world!
            </UncontrolledTooltip> 
            */}
      </Row>
    </Container>
  );
}
