// Components
import { Button } from '@octano/global-ui';
import { Col } from 'reactstrap';

// Types
import { CSSProperties } from 'react';

export type ButtonItem = {
  text: string;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'danger';
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  outlined?: boolean;
  type?: 'submit' | 'button' | 'reset';
};

type BottomButtonsProps = {
  buttons?: ButtonItem[];
  hint?: string;
};

// Render
const BottomButtons = ({ buttons, hint }: BottomButtonsProps) => {
  return (
    <>
      <Col xs={12} className="text-right mt-5">
        {buttons?.map((item, i) => (
          <Button key={`button-${i}`} {...item} />
        ))}

        {!!hint?.trim() && <p className="fs-14 mt-3">{hint?.trim()}</p>}
      </Col>
    </>
  );
};

export default BottomButtons;
