import { useCallback, useMemo } from 'react';

import { Button, ColumnTable, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { RequiredFile } from '../types';
import dayjs from 'dayjs';
const keyPrefix = 'views.postulations.requirements';

type UseRequiredFilesColumnsProps = {
  onPressDestroy?: (id: number) => void;
  onPressDownload?: (requiredFile: RequiredFile) => void;
  onPressUpload?: (requiredFile: RequiredFile) => void;
};

export default function useRequiredFilesColumns({
  onPressDestroy,
  onPressDownload,
  onPressUpload,
}: UseRequiredFilesColumnsProps) {
  const { t } = useTranslation('translation', { keyPrefix });

  const openUpload = useCallback(
    (props: RequiredFile) => {
      onPressUpload && onPressUpload(props);
    },
    [onPressUpload],
  );

  const handleDownload = useCallback(
    (props: RequiredFile) => {
      onPressDownload && onPressDownload(props);
    },
    [onPressDownload],
  );

  const handleDestroy = useCallback(
    (id: number) => {
      onPressDestroy && onPressDestroy(id);
    },
    [onPressDestroy],
  );

  const handleDownloadTemplate = (templateUrl: string) => {
    const url = window?.location?.origin + templateUrl;
    window?.open(url, '_blank')?.focus();
  };

  const columns = useMemo<ColumnTable<RequiredFile>[]>(() => {
    return [
      {
        columnName: 'displayName',
        headerText: t('documentType'),
        tdClassName: 'text-left',
        thClassName: 'text-left',
        width: '350px',
        cellFormat: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        columnName: 'name',
        headerText: t('documentName'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) =>
          row?.url ? `${row?.name}.${row?.extension}` : '',
      },
      {
        columnName: 'url',
        headerText: t('documentOptions'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) => {
          return (
            <div>
              <span className="mx-2" onClick={() => handleDownload(row)}>
                <Icon
                  name="download"
                  color={value?.trim() ? 'primary' : 'disabled'}
                  size={18}
                />
              </span>
              <span
                className="mx-2"
                onClick={() => !!value?.trim() && handleDestroy(row?.id)}
              >
                <Icon
                  name="trash"
                  color={value?.trim() ? 'primary' : 'disabled'}
                  size={18}
                />
              </span>
            </div>
          );
        },
      },
      {
        columnName: 'createdAt',
        headerText: t('documentDate'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY') : '',
      },
      {
        columnName: 'action',
        headerText: 'Formulario',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '200px',
        cellFormat: ({ row }) => {
          if (!row.templateUrl) {
            return null;
          }
          return (
            <div>
              <Button
                text={'Descargar'}
                icon="download"
                size="sm"
                style={{ minWidth: 170 }}
                outlined
                onClick={() => handleDownloadTemplate(row.templateUrl!)}
              />
            </div>
          );
        },
      },
      {
        columnName: 'action',
        headerText: t('documentUpload'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '200px',
        cellFormat: ({ row }) => {
          return (
            <div>
              <Button
                text={t('upload')}
                icon="upload"
                size="sm"
                style={{ minWidth: 170 }}
                outlined
                onClick={() => openUpload(row)}
              />
            </div>
          );
        },
      },
    ];
  }, [t, handleDownload, handleDestroy, openUpload]);

  return columns;
}
