import { OfferDetail } from '../../types/jobOffer';
import { Filter } from '../../types/jobOfferFilter';
import { PaginatedRequestType } from '../../types/pagination';
import { OFFERS } from '../endpoints';
import request from '../request';

/**
 * Obtiene el listado de ofertas publicas.
 * @param itemsPerPage Cantidad de items por página
 */
export function getOffersList(params: {
  itemsPerPage: number;
  page: number;
  search: string;
  workingDay: number;
  campus: number;
  career: number;
}) {
  return request<PaginatedRequestType<OfferDetail>>(OFFERS.LIST, {
    params,
    authRequired: false,
  });
}

export function getFilters() {
  return request<Filter>(OFFERS.FILTERS, {
    authRequired: false,
  });
}

export function getPublicOfferDetail(offerId: number) {
  const url = OFFERS.PUBLIC_DETAIL(offerId);
  return request<OfferDetail>(url, {
    authRequired: false,
  });
}

export function getOfferDetail(offerId: number) {
  const url = OFFERS.DETAIL(offerId);
  return request<OfferDetail>(url);
}
