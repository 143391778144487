// Components
import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  TextOutlinedInput,
  useMobile,
} from '@octano/global-ui';
import { Col } from 'reactstrap';

// Hooks
import { Control, UseFormWatch, useFieldArray } from 'react-hook-form';
import { Fragment, useMemo } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { RegisterFormFields } from '../types';
import { TEXTINPUT_MAXLENGTH } from '../../../../config/constants';

interface Props {
  control: Control<RegisterFormFields>;
  watch: UseFormWatch<RegisterFormFields>;
  educationalLevels?: OutlinedSelectOptionType[];
  collegeDegrees?: OutlinedSelectOptionType[];
}

// Render
export default function AcademicInformation({
  control,
  watch,
  educationalLevels = [],
  collegeDegrees = [],
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educationalInformation',
  });

  const academicInformation = useMemo(
    () => (
      <>
        {fields.map((item, i) => (
          <Fragment key={item.id}>
            <Col xs={12} md={4}>
              <OutlinedSelect
                label={t('common.terms.educationalLevel')}
                placeholder={t('common.terms.educationalLevel')}
                options={educationalLevels}
                name={`educationalInformation.${i}.educationalLevel` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
              />
            </Col>
            <Col xs={12} md={4}>
              <OutlinedSelect
                label={t('common.terms.title')}
                placeholder={t('common.terms.title')}
                options={collegeDegrees}
                name={`educationalInformation.${i}.collegeDegree` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
              />
            </Col>
            <Col xs={12} md={3}>
              <TextOutlinedInput
                label={t('common.terms.houseOfStudies')}
                placeholder={t('common.terms.houseOfStudies')}
                name={`educationalInformation.${i}.college` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
                maxLength={TEXTINPUT_MAXLENGTH}
              />
            </Col>
            <Col xs={12} md={1}>
              <Button
                icon="trash"
                size="md"
                className="pr-3 pl-4 mt-4 w-100"
                outlined
                onClick={() => {
                  fields.length > 1 && remove(i);
                }}
              />
            </Col>
          </Fragment>
        ))}
      </>
    ),
    [collegeDegrees, control, educationalLevels, fields, remove, t],
  );

  return (
    <>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">
          {t('views.account.registration.form.academicData')}
        </p>
      </Col>
      {academicInformation}
      <Col xs={12}>
        <Button
          size="md"
          text={'+ ' + t('common.actions.newCollegeDegree')}
          className="float-right mt-4"
          style={{ width: isMobile ? '100%' : '199.81px' }}
          onClick={() =>
            append({
              educationalLevel: null,
              collegeDegree: null,
              college: null,
            })
          }
        />
      </Col>
    </>
  );
}
