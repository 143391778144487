// Components
import AccountContainer from '../shared/AccountContainer';
import { addToast, Button, TextInput } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

// Hooks
import { useValidations } from '../../../hooks/useValidations';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import { createAccount, uploadFile } from '../../../api/requests/registration';

// Types
import { PasswordRecoveryFormFields } from '../../../types/auth';
import { PathsLayouts } from '../../../config/routes';
import { BaseRegisterFormFields, RegisterFormFile } from './types';
import { TEXTINPUT_MAXLENGTH } from '../../../config/constants';

// Render
export default function RegistrationForm() {
  const history = useHistory();
  let { state } = useLocation<{
    form: BaseRegisterFormFields;
    files: RegisterFormFile;
  }>();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<PasswordRecoveryFormFields>({
    mode: 'onChange',
    defaultValues: {
      password: undefined,
      passwordConfirm: undefined,
    },
  });

  const password = watch('password');

  const {
    validateTextNotEmpty,
    validatePassword,
    validateRepeatPassword,
    msgValidations,
    validateEmail,
  } = useValidations();

  const handleSubmitRecovery = async (values: PasswordRecoveryFormFields) => {
    const { form, files } = state;

    const formValues = {
      ...form,
      ...values,
      educationalInformation: form.educationalInformation.map(
        ({ collegeDegree, college, educationalLevel }) => {
          return {
            collegeDegreeId: collegeDegree!.value,
            educationalLevelId: educationalLevel!.value,
            college: college,
          };
        },
      ),
      country: form.country?.value,
      commune: form.commune?.value,
      addressStreet: [form.city?.trim(), form.residence?.trim()]
        ?.filter((e) => !!e?.trim())
        ?.join(', '),
    };

    const { data, error } = await createAccount(formValues);

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: error.data?.message ?? t('common.responses.failedCreation'),
      });
    } else {
      const { curriculum, additionalFile } = files;
      if (curriculum) {
        await Promise.all([
          uploadFile(data.id, 'curriculum', curriculum as any),
          ...(additionalFile
            ? [uploadFile(data.id, 'additionalFile', additionalFile as any)]
            : []),
        ]);
      }

      addToast({
        icon: 'success',
        color: 'success',
        text: t('common.responses.successfulCreation'),
      });
      history.push(PathsLayouts.auth);
    }
  };

  return (
    <AccountContainer
      title={t('views.account.registration.title')}
      subTitle={t('views.account.registration.subtitle2')}
      hasLinkUndercard
      wrapped
    >
      <Row>
        <Col xs={12} className="pt-2">
          <form onSubmit={handleSubmit(handleSubmitRecovery)}>
            <Col xs={12}>
              <TextInput
                name="email"
                value={state.form.email}
                label={t('common.terms.email')}
                rules={{
                  validate: {
                    email: validateEmail,
                    notEmpty: validateTextNotEmpty,
                  },
                }}
                disabled
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="password"
                control={control}
                type="password"
                label={t('common.terms.password')}
                tooltip={msgValidations.invalidPassword}
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    password: validatePassword,
                  },
                }}
                maxLength={TEXTINPUT_MAXLENGTH}
              />
            </Col>

            <Col xs={12}>
              <TextInput
                name="passwordConfirm"
                control={control}
                type="password"
                label={t('common.terms.passwordConfirm')}
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    password: validateRepeatPassword(password),
                  },
                }}
                maxLength={TEXTINPUT_MAXLENGTH}
              />
            </Col>

            <div className="py-4">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                text={t(`common.actions.createUser`)}
                fullwidth
              />

              <Button
                outlined
                type="button"
                loading={isSubmitting}
                disabled={isSubmitting}
                text="volver"
                onClick={() => history.push('/public/registration', state)}
                fullwidth
              />
            </div>
          </form>
        </Col>
      </Row>
    </AccountContainer>
  );
}
