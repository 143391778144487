import { Box } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { OfferDetail } from '../../../types/jobOffer';

interface Props {
  offer: OfferDetail;
}

const JobCard = ({ offer }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    id,
    name,
    school,
    career,
    campus,
    workingDay,
    modality,
    description,
    createdAt,
  } = offer;

  const goToOfferDetail = () => {
    history.push({ pathname: `/jobs/detail/${id}` });
  };

  return (
    <Box
      key={offer.id}
      body={
        <Row onClick={goToOfferDetail}>
          <Col xs={12}>
            <p className="fw-700 fs-20">{name}</p>
          </Col>
          <Col xs={12} className="pl-5">
            <p className="mb-1 fs-12">
              {school.name} ,{career.name}
            </p>
            <p className="mb-1 fs-12">
              {t(`common.terms.campus`)} {campus.name}
            </p>
            <p className="mb-1 fs-12">
              {t(`common.terms.schedule`)} {workingDay.name}
            </p>
            <p className="mb-1 fs-12">
              {t(`common.terms.modality`)} {modality.name}
            </p>
          </Col>
          <Col xs={12}>
            <div
              style={{
                height: 20,
                maxWidth: '75%',
              }}
            >
              <p
                className="mb-0 fs-14"
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {description}
              </p>
            </div>

            <p className="mb-0 text-right fs-14 fw-600">
              fecha de publicación: {dayjs(createdAt).format('DD-MM-YYYY')}
            </p>
          </Col>
        </Row>
      }
      variant="outlined"
      fullwidth
      color="primary"
    />
  );
};

export default JobCard;
