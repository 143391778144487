// Components
import { TextAreaInput, TextOutlinedInput } from '@octano/global-ui';
import { Col } from 'reactstrap';

// Hooks
import { Control } from 'react-hook-form';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { RegisterFormFields } from '../types';
import LengthCounter from '../../../../components/Form/LengthCounter';
import {
  TEXTAREA_MAXLENGTH,
  TEXTINPUT_MAXLENGTH,
} from '../../../../config/constants';

interface Props {
  control: Control<RegisterFormFields>;
}

// Render
export default function ProfessionalInformation({ control }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">
          {t('views.account.registration.form.linkedin')}
        </p>
      </Col>
      <Col xs={12} md={4}>
        <TextOutlinedInput
          label={t('common.terms.linkedin')}
          placeholder={t('common.terms.linkedin')}
          name="linkedin"
          control={control}
          maxLength={TEXTINPUT_MAXLENGTH}
        />
        <LengthCounter name="linkedin" maxLength={TEXTINPUT_MAXLENGTH} />
      </Col>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">
          {t('views.account.registration.form.professionalProfileSummary')}
        </p>
      </Col>
      <Col xs={12}>
        <TextAreaInput
          label=""
          placeholder={t('views.account.registration.form.description')}
          name="professionalResume"
          control={control}
          rules={{ required: t('common.validations.required') }}
          maxLength={TEXTAREA_MAXLENGTH}
        />
        <LengthCounter
          name="professionalResume"
          maxLength={TEXTAREA_MAXLENGTH}
        />
      </Col>
    </>
  );
}
