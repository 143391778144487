import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
  useMobile,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Form, Row } from 'reactstrap';

import { getFilters } from '../../../api/requests/offer';
import useQuery from '../../../hooks/customQuery';
import { mapNameAndIdToLabelAndValue } from '../../../utils/mapNameAndIdToLabelAndValue';

interface FormFields {
  keyWord: string;
  campus: SelectOptionType | null;
  career: SelectOptionType | null;
  workingDay: SelectOptionType | null;
}
interface ListItems {
  [key: string]: SelectOptionType[];
}

const Filters = () => {
  const prefix = 'views.jobsList.filtersPart';
  const isMobile = useMobile();
  const { t } = useTranslation();
  const history = useHistory();
  const urlSearchParams = useQuery();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormFields>({
    mode: 'onChange',
  });
  const [lists, setLists] = useState<ListItems>({
    workingDay: [],
    campu: [],
    career: [],
  });

  const getData = useCallback(async () => {
    const { data } = await getFilters();
    if (data) {
      const workingDay = data.workingDays.map(mapNameAndIdToLabelAndValue);
      const career = data.careers.map(mapNameAndIdToLabelAndValue);
      const campus = data.campus.map(mapNameAndIdToLabelAndValue);
      setLists({ campus, career, workingDay });
    }
  }, []);

  const applyFilters = async (values: FormFields) => {
    let newParams = {};
    Object.entries(values).forEach(
      ([key, value]: [string, SelectOptionType | string]) => {
        if (!value) return;
        if (typeof value !== 'string') {
          newParams = { ...newParams, [key]: value.value, page: '1' };
        } else {
          newParams = { ...newParams, [key]: value, page: '1' };
        }
      },
    );

    const newUrlSearch = new URLSearchParams(newParams);

    history.push({
      pathname: '/public/jobs/',
      search: newUrlSearch.toString(),
    });
  };

  const clearFilters = () => {
    urlSearchParams.delete('keyWord');
    urlSearchParams.delete('campus');
    urlSearchParams.delete('career');
    urlSearchParams.delete('workingDay');

    reset({
      keyWord: '',
      campus: null,
      career: null,
      workingDay: null,
    });
    history.push('/public/jobs/?page=1');
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
      <Row>
        <Col xs={12}>
          <h1 className="fs-25 fw-700 text-primary">{t(`${prefix}.title`)}</h1>
        </Col>
        <Col xs={12}>
          <p className="fs-18 mb-4">{t(`${prefix}.description`)}</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(applyFilters)}>
        <Row>
          <Col xs={12} md={4}>
            <OutlinedSelect
              name="campus"
              label={t(`common.terms.campus`)}
              placeholder={t(`${prefix}.filters.placeholderSchool`)}
              options={lists.campus}
              control={control}
            />
          </Col>
          <Col xs={12} md={4}>
            <OutlinedSelect
              name="career"
              label={t(`common.terms.career`)}
              placeholder={t(`${prefix}.filters.placeholderContractType`)}
              options={lists.career}
              control={control}
            />
          </Col>
          <Col xs={12} md={4}>
            <OutlinedSelect
              name="workingDay"
              label={t(`common.terms.workingDay`)}
              placeholder={t(`${prefix}.filters.placeholderWorkingDay`)}
              options={lists.workingDay}
              control={control}
            />
          </Col>
          <Col xs={12} md={9}>
            <TextOutlinedInput
              name="keyWord"
              type="text"
              label={t(`${prefix}.filters.keyWord`)}
              placeholder={t(`${prefix}.filters.placeholderKeyWord`)}
              control={control}
            />
          </Col>
          <Col xs={12} md={3} className="align-self-end">
            <Row>
              <Col xs={12} md={6}>
                <Button
                  text={t(`common.actions.search`)}
                  className="mb-3"
                  style={{ minHeight: '42px' }}
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  onClick={clearFilters}
                  style={{ minHeight: '42px' }}
                  outlined
                  text={t(`common.actions.clean`)}
                  fullwidth
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Filters;
