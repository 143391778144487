// Components
import RegisterForm from '../register/parts/RegisterForm';
import BottomButtons from '../register/parts/BottomButtons';
import { Dialog, addToast, useMobile } from '@octano/global-ui';
import { Card, Col, Form, Row } from 'reactstrap';

// Hooks
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import useUserState from '../../../hooks/useUserState';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import clsx from 'clsx';
import { mapNameAndIdToLabelAndValue as mapEntity } from '../../../utils/mapNameAndIdToLabelAndValue';

// Types
import { FileTypes } from '../../postulation/steps/files/types';
import { RegisterFormFields } from '../register/types';

// Redux
import {
  useGetProfileQuery,
  useUpdateFileMutation,
  useUpdateProfileMutation,
} from '../api';

// Render
const EditProfileScreen = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [updateProfile, { isLoading: updatingProfile }] =
    useUpdateProfileMutation();
  const [uploadFile, { isLoading: updateFiles }] = useUpdateFileMutation();

  const { data: user } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { setUserData } = useUserState();

  const methods = useForm<RegisterFormFields>();

  const { reset } = methods;

  const isSubmitting = useMemo(
    () => methods?.formState?.isSubmitting || updatingProfile || updateFiles,
    [methods?.formState?.isSubmitting, updateFiles, updatingProfile],
  );

  const onSubmit = useCallback(
    async (values: RegisterFormFields) => {
      try {
        const { curriculum, additionalFile, ...form } = values;
        const payload = {
          ...form,
          ...values,
          rut: user?.rut || undefined,
          passport: user?.passport || undefined,
          email: user?.email || undefined,
          educationalInformation: form?.educationalInformation?.map(
            ({ collegeDegree, college, educationalLevel }) => {
              return {
                collegeDegreeId: collegeDegree!.value,
                educationalLevelId: educationalLevel!.value,
                college: college,
              };
            },
          ),
          country: form?.country?.value,
          commune: form?.commune?.value,
          addressStreet: [form?.city?.trim(), form?.residence?.trim()]
            ?.filter((e) => !!e?.trim())
            ?.join(', '),
        };
        const response = await updateProfile(payload)?.unwrap();
        setUserData({ ...response });
        if (curriculum) {
          await Promise.all([
            ...(curriculum && !(curriculum as any)?.id
              ? [
                  uploadFile({
                    name: 'curriculum',
                    file: curriculum as any,
                  }),
                ]
              : []),
            ...(curriculum && !(additionalFile as any)?.id
              ? [
                  uploadFile({
                    name: 'additionalFile',
                    file: additionalFile as any,
                  }),
                ]
              : []),
          ]);
        }
        addToast({
          icon: 'success',
          color: 'success',
          text: t('views.account.editProfile.success'),
        });
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('views.account.editProfile.failed'),
        });
      }
    },
    [
      setUserData,
      t,
      updateProfile,
      uploadFile,
      user?.email,
      user?.passport,
      user?.rut,
    ],
  );

  const retreiveCityFromAddress = (address?: string) => {
    if (address?.trim()) {
      const components = address?.split(',');
      if (components && components[0]?.trim()) {
        return components[0]?.trim();
      }
    }
    return '';
  };

  const retreiveResidenceFromAddress = (address?: string) => {
    if (address?.trim()) {
      const components = address?.split(',');
      if (components && components[1]?.trim()) {
        return components[1]?.trim();
      }
    }
    return '';
  };

  useEffect(() => {
    if (user) {
      reset({
        rut: user?.rut || undefined,
        passport: user?.passport || undefined,
        usePassport: !!user?.passport,
        names: user?.name,
        maternalLastName: user?.maternalLastName,
        paternalLastName: user?.paternalLastName,
        phone: user?.phone,
        email: user?.email,
        city: retreiveCityFromAddress(user?.profile?.addressStreet),
        residence: retreiveResidenceFromAddress(user?.profile?.addressStreet),
        linkedin: user?.profile?.linkedin || undefined,
        profession: user?.profile?.profession || undefined,
        professionalResume: user?.profile?.professionalResume || undefined,
        country: user?.country ? mapEntity(user?.country) : undefined,
        region: user?.profile?.commune?.region
          ? mapEntity(user?.profile?.commune?.region)
          : undefined,
        commune: user?.profile?.commune
          ? mapEntity(user?.profile?.commune)
          : undefined,
        educationalInformation:
          user?.educationalInformation?.map((e) => ({
            ...e,
            collegeDegree: e?.educationalLevel
              ? mapEntity(e?.collegeDegree as any)
              : undefined,
            educationalLevel: e?.educationalLevel
              ? mapEntity(e?.educationalLevel as any)
              : undefined,
          })) || [],
        curriculum: user?.files?.find(
          (f) => f.reason === FileTypes.ProfileCurriculum,
        ),
        additionalFile: user?.files?.find(
          (f) => f.reason !== FileTypes.ProfileCurriculum,
        ),
      });
    }
  }, [reset, user]);

  return (
    <>
      <div className={clsx('py-5', isMobile ? 'px-4' : 'px-5 mx-3')}>
        <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12}>
                  <h1 className="fs-25 fw-700 text-primary">
                    {t(`views.account.editProfile.title`)}
                  </h1>
                  <p className="fs-18 mb-4">
                    {t(`views.account.editProfile.subtitle`)}
                  </p>
                </Col>

                <RegisterForm isEditForm />

                <BottomButtons
                  buttons={[
                    {
                      type: 'submit',
                      text: t('common.actions.updateUser'),
                      style: { width: isMobile ? '100%' : '246px' },
                      className: 'mb-3',
                      disabled: isSubmitting,
                      loading: isSubmitting,
                    },
                  ]}
                />
              </Row>
            </Form>
          </FormProvider>
        </Card>
      </div>
      <Dialog />
    </>
  );
};

export default EditProfileScreen;
