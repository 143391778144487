import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Row } from 'reactstrap';

import DisplayErrorCard from '../../components/info/DisplayError';
import LoadingCard from '../../components/info/Loading';
import Description from '../../components/steps/Description';
import List from '../../components/steps/List';
import { PathsLayouts } from '../../config/routes';
import useScrollPosition from '../../hooks/useScrollPosition';
import { Base } from '../../types/GenericFormTypes';
import { OfferStatus, OfferType } from '../../types/jobOffer';
import { getJWT } from '../../utils/auth';
import ApplicationModal from './parts/ApplicationModal';
import FloatingButton from './parts/FloatingButton';
import JobDetailHeader from './parts/HeaderDetail';
import { useGetOfferQuery } from '../jobsList/api';
import { useRejectPrivateOfferMutation } from '../private-offers/api';
import { Dialog, addToast, showDialogConfirm } from '@octano/global-ui';

interface ParamsType {
  id: string;
}

enum ModalType {
  None,
  Applicant,
}

const prefix = 'views.jobsDetail';

export default function JobDetailRoute() {
  const history = useHistory();
  const { id } = useParams<ParamsType>();
  const { t } = useTranslation();
  const { show } = useScrollPosition({ scrollY: 472 });
  const isLogged = Boolean(getJWT());

  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.None);

  const [rejectPrivateOffer] = useRejectPrivateOfferMutation();

  const {
    data: offer,
    isLoading,
    error,
  } = useGetOfferQuery({ id, isLogged }, { refetchOnMountOrArgChange: true });

  const languages = useMemo<Base[]>(
    () =>
      offer?.offerLanguageLevels?.map(({ language, languageLevel }) => ({
        name: `${language.name} - ${languageLevel.name}`,
      })) || [],
    [offer?.offerLanguageLevels],
  );

  const applicant = useCallback(() => {
    if (isLogged) {
      history.push({
        pathname: `/postulation/offer/${id}/apply`,
      });
    } else {
      history.push(PathsLayouts.auth);
    }
  }, [history, id, isLogged]);

  const handleReject = useCallback(async () => {
    try {
      await rejectPrivateOffer(id).unwrap();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.rejectSuccess`),
      });
      history.push({
        pathname: `/public/jobs`,
      });
    } catch (_error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.rejectError`),
      });
    }
  }, [rejectPrivateOffer, id, t, history]);

  const handleRequestReject = useCallback(() => {
    showDialogConfirm({
      title: t(`${prefix}.rejectTitle`),
      subtitle: t(`${prefix}.rejectSubtitle`),
      btnClose: {
        text: t(`${prefix}.rejectCancel`),
      },
      btnConfirm: {
        text: t(`${prefix}.rejectConfirm`),
        onConfirm: handleReject,
      },
    });
  }, [t, handleReject]);

  if (isLoading) {
    return (
      <Card className="p-4 my-4">
        <LoadingCard />
      </Card>
    );
  }

  if (error) {
    if ((error as any)?.status === 400 && (error as any)?.data?.message) {
      return (
        <Card className="p-4 my-4">
          <DisplayErrorCard
            typeError="FETCH_ERROR"
            body={(error as any)?.data?.message}
          />
        </Card>
      );
    }
    return (
      <Card className="p-4 my-4">
        <DisplayErrorCard typeError={(error as any)?.status} />
      </Card>
    );
  }

  return (
    <>
      <Card className="p-4 my-4">
        <Row>
          <JobDetailHeader
            career={`${offer!.school.name}, ${offer!.career.name}`}
            period={offer!.period.name}
            subjectHours={offer!.subjectHours}
            name={offer!.name}
            campus={offer!.campus.name}
            working_day={offer!.workingDay.name}
            modality={offer!.modality.name}
            description={offer!.description}
            benefits={offer!.benefits || []}
            offerId={offer!.id}
            updatedAt={offer!.updatedAt}
            status={offer!.statusOffer}
            hasApplication={Boolean(offer?.hasApplication)}
            isPrivate={offer?.type === OfferType.Private}
            onClick={applicant}
            onReject={handleRequestReject}
          />
        </Row>
      </Card>
      <Card className="mt-4 p-4">
        <Row>
          <Description
            title={t(`${prefix}.description`)}
            description={offer!.description}
            item={Boolean(offer!.description)}
          />
          <Description
            title={t(`${prefix}.levelResponsibility`)}
            description={offer!.levelResponsibility}
            item={Boolean(offer!.levelResponsibility)}
          />
          <List title={t(`${prefix}.educations`)} list={offer!.educations} />
          <List
            title={t(`${prefix}.competencies`)}
            list={offer!.competencies}
          />
          <List
            title={t(`${prefix}.certifications`)}
            list={offer!.certifications}
          />
          <List title={t(`${prefix}.licenses`)} list={offer!.licenseTypes} />
          <List
            title={t(`${prefix}.requirements`)}
            list={offer!.requirements}
          />
          <List title={t(`${prefix}.languages`)} list={languages} />
          <Description
            title={t(`${prefix}.knowledge`)}
            description={offer!.knowledge}
            item={Boolean(offer!.knowledge)}
          />
          <List title={t(`${prefix}.benefits`)} list={offer!.benefits} />
          <List
            title={t(`${prefix}.applicationStages.title`)}
            subtitle={t(`${prefix}.applicationStages.description`)}
            list={offer!.applicationStages}
          />
          {offer?.laws?.map((law, index) => (
            <Description
              key={index}
              title={law.name}
              description={law.description}
              item={Boolean(true)}
            />
          ))}
          {!Boolean(offer?.hasApplication) && (
            <FloatingButton
              text={offer!.name}
              hide={
                !show ||
                ![OfferStatus.Available, OfferStatus.InProgress].includes(
                  offer?.statusOffer.id!,
                )
              }
              onClick={applicant}
            />
          )}
          <ApplicationModal
            campusEmail={offer!.campus.email}
            isOpen={openedModal === ModalType.Applicant}
            onClose={() => setOpenedModal(ModalType.None)}
          />
        </Row>
      </Card>
      <Dialog />
    </>
  );
}
