import { Dispatch } from 'react';

export enum StepActionType {
  NEXT_STEP = 'NEXT_STEP',
  PREV_STEP = 'PREV_STEP',
  SET_STEP = 'SET_STEP',
}

type PayloadType = {
  currentStep: number;
  alreadyApplied: boolean;
};

export type StepAction =
  | { type: StepActionType.NEXT_STEP }
  | { type: StepActionType.PREV_STEP }
  | {
      type: StepActionType.SET_STEP;
      payload: PayloadType;
    };

export type StepState = {
  currentStep: number;
  alreadyApplied: boolean;
};

export type StepContextType = {
  state: StepState;
  dispatch: Dispatch<StepAction>;
};

export enum CurrentSteps {
    Received = 'received',
    Resume = 'resume',
    FileUpload = 'file-upload',
    Review = 'review',
    Discarded = 'discarded',
    Approved = 'approved',
    Hired = 'hired',
  }

export enum NoCurrentStep {
  None = 'none',
}

export interface CurrentStep {
  id?: number;
  currentStep: CurrentSteps;
}

export function isCurrentStepType(input: string): input is CurrentSteps {
  return Object.values(CurrentSteps).includes(input as CurrentSteps);
}
