import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, CardBody, CardTitle, Container, Row } from 'reactstrap';
import { ResumRequest } from '../types';
import { mapLanguageItems, mapResumeItems } from './utils';

interface Props {
  resume: ResumRequest;
  title: string;
}

const keyPrefix = 'views.postulation.resume.descriptionSection';

export default function Description({ resume, title }: Props) {
  const { t } = useTranslation('translation', { keyPrefix });

  const educations = useMemo(
    () => mapResumeItems(resume.educations, 'name'),
    [resume.educations],
  );

  const competencies = useMemo(
    () => mapResumeItems(resume.competencies, 'name'),
    [resume.competencies],
  );

  const requirements = useMemo(
    () => mapResumeItems(resume.requirements, 'name'),
    [resume.requirements],
  );

  const offerLanguageLevel = useMemo(
    () => mapLanguageItems('name', resume.offerLanguageLevels),
    [resume.offerLanguageLevels],
  );

  return (
    <Container fluid className="mb-3">
      <Row className="h-100" style={{ height: 300 }}>
        <Col xs={12}>
          <h4 className="text-primary fs-30 fw-700 mb-3 text-uppercase">
            {t('title')}: {title}
          </h4>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="text-dark fw-700">
                {t('description')}
              </CardTitle>
              <div
                style={{
                  maxHeight: 85,
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <p>{resume.description}</p>
              </div>
              <p className="m-0 mt-4">
                <strong>{t('campus')}: </strong>
                {resume.campus.name}
              </p>
              <p className="m-0">
                <strong>{t('workingDay')}: </strong>
                {resume.workingDay.name}
              </p>
              <p className="m-0">
                <strong>{t('modality')}: </strong>
                {resume.modality.name}
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Card style={{ height: '100%' }}>
            <CardBody>
              <CardTitle tag="h5" className="text-dark fw-700">
                {t('Requirements')}
              </CardTitle>
              <ul className="pl-3">
                <li>
                  <strong>{t('educations')}: </strong>
                  {educations}
                </li>
                <li>
                  <strong>{t('skills')}: </strong>
                  {competencies}
                </li>
                <li>
                  <strong>{t('Requirements')}: </strong>
                  {requirements}
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Card style={{ height: '100%' }}>
            <CardBody>
              <CardTitle tag="h5" className="text-dark fw-700">
                {t('knowledge')}
              </CardTitle>
              <div
                style={{
                  maxHeight: 85,
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <p>{resume.knowledge}</p>
              </div>

              <ul className="pl-3">
                <li>
                  <strong>{t('languages')}: </strong>
                  {offerLanguageLevel}
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} className="d-flex py-3 align-items-center">
          <p>
            <strong>{t('completeFields')}</strong>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
