// Components
import RegisterForm from './parts/RegisterForm';
import BottomButtons from './parts/BottomButtons';
import { Dialog, showDialogConfirm, useMobile } from '@octano/global-ui';
import { Card, Col, Form, Row } from 'reactstrap';

// Hooks
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

// I18n
import { useTranslation } from 'react-i18next';

// Utils
import clsx from 'clsx';

// Types
import {
  BaseRegisterFormFields,
  RegisterFormFields,
  RegisterFormFile,
} from './types';
import { PathsLayouts } from '../../../config/routes';

// Render
const RegisterScreen = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const history = useHistory();

  const methods = useForm<RegisterFormFields>({
    mode: 'onChange',
    defaultValues: {
      educationalInformation: [
        { educationalLevel: null, collegeDegree: null, college: null },
      ],
    },
  });

  const isSubmitting = useMemo(
    () => methods?.formState?.isSubmitting,
    [methods?.formState?.isSubmitting],
  );

  const onSubmit = useCallback(
    (values: RegisterFormFields) => {
      const { curriculum, additionalFile, ...otherValues } = values;
      history.push({
        pathname: `${PathsLayouts.public}/registration/finish`,
        state: {
          form: otherValues as BaseRegisterFormFields,
          files: { curriculum, additionalFile } as RegisterFormFile,
        },
      });
    },
    [history],
  );

  const handleConfirmedBack = useCallback(() => {
    showDialogConfirm({
      icon: {
        name: 'warning',
        color: 'primary',
      },
      title: t('views.account.registration.form.cancelTitle'),
      subtitle: t('views.account.registration.form.cancelMessage'),
      btnClose: {
        text: t('backModal.cancel'),
        onClose: () => {},
      },
      btnConfirm: {
        text: 'SEGUIR CON EL REGISTRO',
        onConfirm: () => history.push(PathsLayouts.public),
      },
    });
  }, [history, t]);

  return (
    <>
      <div className={clsx('py-5', isMobile ? 'px-4' : 'px-5 mx-3')}>
        <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12}>
                  <h1 className="fs-25 fw-700 text-primary">
                    {t(`views.account.registration.title`)}
                  </h1>
                  <p className="fs-18 mb-4">
                    {t(`views.account.registration.subtitle1`)}
                  </p>
                </Col>

                <RegisterForm />

                <BottomButtons
                  buttons={[
                    {
                      outlined: true,
                      color: 'danger',
                      text: t('common.actions.cancel'),
                      onClick: handleConfirmedBack,
                      style: { width: isMobile ? '100%' : '155px' },
                      className: 'mr-4 mb-3',
                    },
                    {
                      type: 'submit',
                      text: t('common.actions.createUser'),
                      style: { width: isMobile ? '100%' : '246px' },
                      className: 'mb-3',
                      disabled: isSubmitting,
                      loading: isSubmitting,
                    },
                  ]}
                />
              </Row>
            </Form>
          </FormProvider>
        </Card>
      </div>
      <Dialog />
    </>
  );
};

export default RegisterScreen;
