import { Button, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

interface Props {
  text: string;
  hide?: boolean;
  onClick?: () => void;
}

export default function FloatingButton({ hide = false, onClick, text }: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  return (
    <>
      {!isMobile && !hide ? (
        <Col
          xs={12}
          className=" ml-auto text-center mt-4 py-2 px-4"
          style={{
            position: 'fixed',
            bottom: '15vh',
            right: 20,
            width: '254px',
            top: 80,
          }}
        >
          <Button
            onClick={onClick}
            text={t(`common.actions.apply`)}
            className="mt-4 mb-4"
            fullwidth
            style={{ position: 'relative', top: -20 }}
          />
        </Col>
      ) : null}

      {isMobile && !hide ? (
        <Button
          text="postular"
          onClick={onClick}
          outlined
          size="md"
          style={{
            position: 'fixed',
            bottom: '15vh',
            right: 20,
            borderRadius: 50,
            height: 10,
            backgroundColor: '#ffffff',
          }}
        />
      ) : null}
    </>
  );
}
