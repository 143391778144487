import { addToast, CheckInput, Icon } from '@octano/global-ui';
import { Fragment, useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { uploadDocument } from '../../../request';
import { FileFormField, FileTypes, UploadType } from '../types';
import FileUpload from './FileUpload';

const prefix = 'views.postulation.files.degreesSection';

export default function DegreeForm() {
  const { t } = useTranslation();
  const { postulationId } = useParams<{ postulationId: string }>();
  const {
    control,
    formState: { isSubmitted },
    watch,
  } = useFormContext<FileFormField>();

  const { fields, append } = useFieldArray<FileFormField>({
    control,
    name: 'collegeDegrees',
  });

  const arr = watch('collegeDegrees');

  const collegeDegrees = useMemo(
    () => (
      <>
        {fields.map((item, i) => (
          <Fragment key={item.id}>
            <Col xs={12} md={4} className="d-flex justify-content-between">
              <CheckInput
                name={`collegeDegrees.${i}.isSelected` as const}
                label={item.fileName}
                control={control}
              />
              <a href={item.url} target="_blank" rel="noreferrer">
                <Icon name="download"></Icon>
              </a>
            </Col>
          </Fragment>
        ))}
      </>
    ),
    [control, fields],
  );

  const submitFunction = useCallback(
    async (postulationId: string, values: UploadType) => {
      let { error, data } = await uploadDocument(postulationId, values);
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.onSave.error`),
        });
      } else {
        append({
          fileId: data.id,
          fileName: data.name,
          isSelected: true,
          url: data.url,
        });
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.onSave.success`),
        });
      }
    },

    [append, t],
  );

  const errors = useMemo(() => {
    return !arr.some((c) => c.isSelected) && isSubmitted
      ? t('common.validations.required')
      : undefined;
  }, [arr, isSubmitted, t]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={6}>
          <p className="text-primary fs-20 pl-3 fw-700 mb-3 text-uppercase mt-5 ">
            {t('views.postulation.files.academicDegree')}
          </p>
          <p className="pl-0">
            {t('views.postulation.files.academicDegreeInformation')}
          </p>
          {collegeDegrees}
          <FileUpload
            name="file"
            btnText={t('views.postulation.files.uploadCollegeDegrees')}
            information={t('views.postulation.files.curriculumInformation')}
            error={errors}
            submitFunction={(values) =>
              submitFunction(postulationId, {
                reason: FileTypes.ProfileCollegeDegree,
                document: values,
              })
            }
          />
        </Col>
      </Row>
    </Container>
  );
}
