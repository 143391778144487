import { Container } from 'reactstrap';

import RoutesByLayout from '../components/RoutesByLayout/RoutesByLayout';
import { PathsLayouts } from '../config/routes';
import { withAuthCheckRedirection } from '../hocs/withAuthCheckRedirection';
import { isPostulant } from '../utils/appUser';

function AuthLayout() {
  return (
    <Container fluid className="flex-grow-1">
      <RoutesByLayout />
    </Container>
  );
}

export default withAuthCheckRedirection({
  component: AuthLayout,
  calculateRedirectTo: ({ userData, isLogged }) => {
    if (isLogged) {
      return userData && !isPostulant(userData)
        ? PathsLayouts.public
        : PathsLayouts.public;
    }
  },
});
