import React from 'react';
import { IconProps, UserMenu, Icon } from '@octano/global-ui';
import useUserState from '../../hooks/useUserState';

export interface MenuItem {
  icon?: IconProps['name'];
  text: string;
  onClick: () => void;
}

interface Props {
  logo: React.ReactNode;
  menuItems?: MenuItem[];
}

const CustomNavbar = ({ logo, menuItems = [] }: Props) => {
  const { userData } = useUserState();

  return (
    <div className="g-navbar g-custom-navbar">
      <div className="g-brand-logo">{React.isValidElement(logo) && logo}</div>
      <div className="g-items-container d-flex">
        <UserMenu
          email={userData?.email || ''}
          fullName={`${userData?.fullName || ''}${'\u00A0'.repeat(6)}`}
          menuItems={menuItems}
        />
        <div
          style={{ position: 'relative', left: -20, zIndex: -1, paddingTop: 4 }}
        >
          <Icon name="chevron_down" color="white" />
        </div>
      </div>
    </div>
  );
};

export default CustomNavbar;
