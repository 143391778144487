import { Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface Props {
  name: string;
  url: string;
  documentName: string;
}
export default function File({ name, url, documentName }: Props) {
  const { t } = useTranslation();
  return (
    <Row>
      <Col xs={12}>
        <p className="text-primary fs-20 fw-700 mb-3 text-uppercase">
          {documentName}
        </p>
      </Col>
      <Col xs={12} sm={6}>
        <div
          style={{
            minHeight: 47,
            wordBreak: 'break-all',

            backgroundColor: 'rgba(247, 247, 247, 0.59)',
            border: 'none',
            display: 'flex',
            width: '100',
            minWidth: '1000',
          }}
        >
          <Icon className="my-auto pl-1" name="file_pdf" size={26}></Icon>
          <p className="my-auto mx-3">{name}</p>
        </div>
      </Col>
      <Col xs={12} sm={6}>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="g-button btn btn-outline-primary d-flex justify-content-center align-items-center"
          style={{ display: 'flex', minHeight: 47 }}
        >
          <Icon name="download" className="icon-btn"></Icon>
          <p className="my-auto pl-3 text-uppercase">
            {t('views.postulations.review.download')}
          </p>
        </a>
      </Col>
    </Row>
  );
}
