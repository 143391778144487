import { Redirect } from 'react-router-dom';
import useUserState from '../hooks/useUserState';
import { PathsLayouts } from '../config/routes';
import { UserData } from '../types/auth';
import { Loading } from '@octano/global-ui';

interface CalculateRedirectFunctParams {
  userData?: UserData;
  isLogged?: boolean;
}

type calculateRedirectFunct = (
  params: CalculateRedirectFunctParams,
) => PathsLayouts | undefined;

type HOCReturnType = (props: any) => JSX.Element;

export const withAuthCheckRedirection = ({
  component: Component,
  calculateRedirectTo,
}: {
  component: (props: any) => JSX.Element;
  calculateRedirectTo: calculateRedirectFunct;
}): HOCReturnType => {
  return function WithAuthCheckRedirection(props: any) {
    const { isLogged, userData, hasCheckedToken } = useUserState();

    const redirectPath =
      calculateRedirectTo && calculateRedirectTo({ userData, isLogged });

    if (!hasCheckedToken) {
      return <Loading textBody="" insideCard />;
    }

    return redirectPath ? (
      <Redirect to={redirectPath} />
    ) : (
      <Component {...props} />
    );
  };
};
