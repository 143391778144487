interface Props {
  color?: string;
  text?: string;
}

export default function Legend({ color = 'white', text = 'Leyenda' }: Props) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <span
        className="border border-primary mr-2"
        style={{
          height: 20,
          width: 20,
          backgroundColor: color,
        }}
      />
      <p className="mb-0">{text}</p>
    </div>
  );
}
