import { useCallback, useMemo } from 'react';

// Components
import JobCard from '../jobsList/parts/JobCard';
import Loading from '../../components/info/Loading';
import DisplayError from '../../components/info/DisplayError';
import { Button, TablePagination, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import useQuery from '../../hooks/customQuery';
import { useGetOffersQuery } from './api';
import { useHistory } from 'react-router-dom';

// I18n
import { useTranslation } from 'react-i18next';

// Types
const ITEMS_PER_PAGE = 10;

// Render
const PrivateOffersScreen = () => {
  const { t } = useTranslation();

  const isMobile = useMobile();
  const history = useHistory();
  const URLSearchParams = useQuery();

  const currentPage = useMemo(() => {
    let data = URLSearchParams.get('page');
    if (data) {
      return parseInt(`${data}`, 10);
    }

    return 1;
  }, [URLSearchParams]);

  const offersData = useGetOffersQuery(
    { page: currentPage - 1, itemsPerPage: ITEMS_PER_PAGE },
    { refetchOnMountOrArgChange: true },
  );

  const offers = useMemo(
    () => offersData?.data?.data || [],
    [offersData?.data?.data],
  );
  const totalPages = useMemo(
    () => offersData?.data?.totalPages || 0,
    [offersData?.data?.totalPages],
  );
  const totalOffers = useMemo(
    () => offersData?.data?.total || 0,
    [offersData?.data?.total],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      URLSearchParams.set('page', page.toString());
      history.push({
        pathname: '/private/jobs',
        search: URLSearchParams.toString(),
      });
    },
    [URLSearchParams, history],
  );

  const goBackToList = useCallback(() => {
    history.push('/public/jobs');
  }, [history]);

  return (
    <Row className={clsx('py-5', isMobile ? 'px-4' : 'px-5 mx-3')}>
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <Row className="mb-5">
          <Col className="mb-4" xs={12}>
            <Button
              className="mb-3"
              icon="back"
              onClick={goBackToList}
              outlined
              rounded
              size="sm"
              text={t('views.privateOffers.goBackToList')}
            />
          </Col>
          <Col xs={12}>
            <h1 className="w-100 fw-700 fs-30 text-primary text-uppercase">
              {t('views.privateOffers.title')}
            </h1>
            <span className="w-100 text-light fs-16 fw-400">
              {t('views.privateOffers.subtitle')}
            </span>
          </Col>
        </Row>

        {offersData?.isFetching ||
        offersData?.error ||
        !offersData?.data?.data?.length ? (
          <>
            {offersData?.isFetching ? (
              <Loading />
            ) : (
              <DisplayError
                title={t('views.privateOffers.empty')}
                body=" "
                insideCard
                typeError={(offersData?.error as any)?.status}
              />
            )}
          </>
        ) : (
          <>
            {offers?.map((offer, index) => {
              return (
                <Row className="mb-3" key={index}>
                  <Col xs={12} className="jobCard">
                    <JobCard {...{ offer }} />
                  </Col>
                </Row>
              );
            })}
            <TablePagination
              pagination={{
                currentPage: currentPage,
                itemsPerPage: ITEMS_PER_PAGE,
                totalItems: totalOffers,
                totalPages: totalPages,
                onChangePage: handleChangePage,
              }}
            />
          </>
        )}
      </Card>
    </Row>
  );
};

export default PrivateOffersScreen;
