import { Col } from 'reactstrap';

import { Base } from '../../types/GenericFormTypes';

interface Props {
  title: string;
  subtitle?: string;
  list?: Base[];
}

export default function List({ title, subtitle, list }: Props) {
  return (
    <>
      {list && list.length > 0 && (
        <Col xs={12} className="mb-4">
          <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
            {title}
          </h5>

          {subtitle && <p className="fs-16">{subtitle}</p>}

          <ul>
            {list.map((item, index) => {
              return (
                <li className="fs-16" key={index}>
                  {item.name}
                </li>
              );
            })}
          </ul>
        </Col>
      )}
    </>
  );
}
