import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Resume from '../views/postulation/steps/resume';
import Files from '../views/postulation/steps/files';
import { useStepState } from './useStepState';
import Review from '../views/postulation/steps/review';

const keyPrefix = 'views.postulation.titleSteps';

export const useApplicationSteps = () => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { currentStep } = useStepState();

  const stepsCreateOfferProcess = useMemo(() => {
    const availableSteps = [
      {
        id: 'resume',
        name: t(`step1`),
        component: Resume,
      },
      {
        id: 'file-upload',
        name: t(`step2`),
        component: Files,
      },
      {
        id: 'received',
        name: t(`step3`),
        component: Review,
      },
    ];
    return availableSteps;
  }, [t]);

  /**
   * Contiene dos arrays con las partes de los pasos
   * (Un array con los nombres de los pasos y
   * un array con los componentes asociados a cada paso)
   */
  const availableParts = useMemo(() => {
    const namesSteps: string[] = [];
    const componentsSteps: React.FunctionComponent<any>[] = [];

    stepsCreateOfferProcess.forEach((step, index) => {
      namesSteps.push(step.name);
      componentsSteps.push(step.component);
    });
    return { namesSteps: namesSteps, componentsSteps };
  }, [stepsCreateOfferProcess]);

  const getIndexStep = useCallback(
    /**
     * Una función que devuelve el índice de stepName en la matriz stepsCreateOfferProcess.
     * @param {string} stepId - nombre del paso al que se le recuperará el índice
     * @return {number} índice del paso ingresado
     * **/
    (stepId: string): number => {
      return stepsCreateOfferProcess.findIndex((step) => step.id === stepId);
    },
    [stepsCreateOfferProcess],
  );

  /**
   * El paso actual en base a la variable currentStep
   */
  const CurrentStepComponent = useMemo(() => {
    return availableParts.componentsSteps[currentStep];
  }, [currentStep, availableParts]);

  return {
    stepsCreateOfferProcess,
    ...availableParts,
    CurrentStepComponent,
    getIndexStep,
  };
};
