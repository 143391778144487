import { Logo, Navbar, NavbarProps } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import PublicFooter from '../components/PublicFooter/PublicFooter';
import RoutesByLayout from '../components/RoutesByLayout/RoutesByLayout';
import { PathsLayouts } from '../config/routes';
import useUserState from '../hooks/useUserState';
import CustomNavbar, { MenuItem } from '../components/Navbar/Navbar';
import { useMemo } from 'react';

export default function NavbarLayout() {
  const history = useHistory();
  const { t } = useTranslation();
  const { isLogged, clearUser } = useUserState();

  const downloadUserGuide = () => {
    const url = window?.location?.origin + '/tenant/manual-postulante.pdf';
    window?.open(url, '_blank')?.focus();
  };

  const loggedMenuOptions = useMemo<MenuItem[]>(
    () => [
      {
        icon: 'user',
        text: t(`common.terms.userProfile`),
        onClick: () => {
          history.push('/profile/edit');
        },
      },
      {
        icon: 'offers',
        text: t(`common.terms.myPostulations`),
        onClick: () => {
          history.push('/postulations');
        },
      },
      {
        icon: 'padlock',
        text: t(`common.terms.changePassword`),
        onClick: () => {
          history.push('/profile/password');
        },
      },
      {
        icon: 'academic_offer',
        text: t(`common.terms.userGuide`),
        onClick: downloadUserGuide,
      },
      {
        icon: 'log_out',
        text: t(`common.terms.logOut`),
        onClick: () => {
          clearUser();
          history.push('/');
        },
      },
    ],
    [clearUser, history, t],
  );

  const guestMenuOptions = useMemo<NavbarProps['menuOptions']>(
    () => [
      {
        name: t(`common.terms.logIn`),
        action: () => history.push(PathsLayouts.auth),
        icon: 'user',
      },
      {
        name: t(`common.terms.register`),
        action: () => history.push(`${PathsLayouts.public}/registration`),
        icon: 'update_registration',
      },
    ],
    [history, t],
  );

  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      {isLogged ? (
        <CustomNavbar
          logo={<Logo alt="logo" src="/tenant/logo_white.svg" fallbackSrc="" />}
          menuItems={loggedMenuOptions}
        />
      ) : (
        <Navbar
          Logo={() => (
            <Logo alt="logo" src="/tenant/logo_white.svg" fallbackSrc="" />
          )}
          menuOptions={guestMenuOptions}
        />
      )}
      <Container fluid className="flex-grow-1">
        <RoutesByLayout />
      </Container>
      <PublicFooter />
    </div>
  );
}
