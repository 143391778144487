export interface File {
  id: number;
  folderName: string;
  name: string;
  extension: string;
  userId: string;
  reason: FileTypes;
  url: string;
  createdAt: string;
  isSelected?: boolean;
  certificationId?: number;
  licenseTypeId?: number;
  documentName?: string;
}

export interface FilesRequest {
  files: File[];
  useApplicationCurriculum: boolean;
  requiredCertificates: File[];
  requiredLicenseTypes: File[];
}

export enum FileTypes {
  GeneralFiles = 'archivos_generales',
  ApplicationCertificates = 'certificados_postulacion',
  ApplicationLicenses = 'licencias_postulacion',
  ApplicationCurriculum = 'curriculum_postulacion',
  ProfileCurriculum = 'curriculum_perfil',
  ProfileCollegeDegree = 'titulo_perfil',
  WorkHistory = 'work_history',
}

export interface FileForm {
  fileId: number;
  fileName: string;
  isSelected: boolean;
  url: string;
  certificationId?: number;
  licenseTypeId?: number;
}

export interface FileFormField {
  useApplicationCurriculum: boolean;
  collegeDegrees: FileForm[];
  certifications: FileForm[];
  licenses: FileForm[];
}

export interface UploadType {
  document: File;
  certificationId?: number;
  licenseTypeId?: number;
  reason: FileTypes;
}
