import { CheckInput, DateInput, Icon } from '@octano/global-ui';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import { ResumeFormField } from '../types';

interface Props {
  isVisible: boolean;
}

const keyPrefix = 'views.postulation.resume.availabilitySection';

export default function AvailabilityDate({ isVisible }: Props) {
  const { t } = useTranslation();
  const { setValue, watch, control } = useFormContext<ResumeFormField>();
  const hiringRef = useRef(null);

  const [date] = watch(['immediateInitialAvailabilityDate']);

  useEffect(() => {
    if (date) {
      setValue('initialAvailabilityDate', undefined);
      setValue('finalAvailabilityDate', undefined);
    }
  }, [date, setValue]);

  const conditional = (canValidate?: any) => (fieldValue?: any) => {
    if (!canValidate && !fieldValue) {
      return t('common.validations.required');
    }
    return undefined;
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Container fluid className="mb-4">
      <Row>
        <Col xs={12}>
          <h5 className="text-primary fs-20 fw-700 mb-3 text-uppercase">
            {t(`${keyPrefix}.hiringAvailability`)}
            <span ref={hiringRef} className="ml-1">
              <Icon name="information" className="mb-1" />
            </span>
          </h5>
        </Col>
        <Col xs={12}>
          <CheckInput
            label={t(`${keyPrefix}.immediateInitialAvailabilityDate`)}
            name="immediateInitialAvailabilityDate"
            control={control}
          />
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <DateInput
            label={t(`${keyPrefix}.initialAvailabilityDate`)}
            name="initialAvailabilityDate"
            control={control}
            rules={{
              validate: {
                conditional: conditional(date),
              },
            }}
            minDate={new Date()}
            disabled={date}
          />
        </Col>
        <Col xs={12} md={4}>
          <DateInput
            label={t(`${keyPrefix}.finalAvailabilityDate`)}
            name="finalAvailabilityDate"
            control={control}
            rules={{
              validate: {
                conditional: conditional(date),
              },
            }}
            minDate={new Date()}
            disabled={date}
          />
        </Col>
        {/* 
            <UncontrolledTooltip placement="right" target={hiringRef}>
              Hello world!
            </UncontrolledTooltip>
            */}
      </Row>
    </Container>
  );
}
