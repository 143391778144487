import { addToast, CheckInput } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CardLink, Col, Row } from 'reactstrap';

import diffForHumans from '../../../../../utils/time';
import { uploadDocument } from '../../../request';
import { File, FileFormField, FileTypes, UploadType } from '../types';
import FileUpload from './FileUpload';

interface Props {
  profileCurriculum?: File;
}

const prefix = 'views.postulation.files.curriculumSection';

export default function CurriculumForm({ profileCurriculum }: Props) {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<FileFormField>();
  const { postulationId } = useParams<{ postulationId: string }>();

  const isEnable = watch('useApplicationCurriculum');

  const curriculumUpdate = useMemo(
    () => diffForHumans(profileCurriculum?.createdAt),
    [profileCurriculum?.createdAt],
  );

  const submitFunction = useCallback(
    async (postulationId: string, values: UploadType) => {
      let { error } = await uploadDocument(postulationId, values);

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.onSave.error`),
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.onSave.success`),
        });
      }
    },
    [t],
  );

  return (
    <Col xs={12} sm={6}>
      <p className="fw-600 pb-4">{t('views.postulation.files.information')}</p>
      <FileUpload
        submitFunction={(values) =>
          submitFunction(postulationId, {
            reason: FileTypes.ApplicationCurriculum,
            document: values,
          })
        }
        title={t('views.postulation.files.curriculum')}
        name="file"
        disable={!isEnable}
        information={t('views.postulation.files.curriculumInformation')}
      />

      <Row>
        <Col xs={6}>
          <p className="m-0 p-0">
            {t('views.postulation.files.curriculumUpdate')} {curriculumUpdate}.
          </p>
          <CardLink
            className="m-0 p-0"
            href={profileCurriculum?.url}
            target="_blank"
            rel="noreferrer"
          >
            {t('views.postulation.files.seeCurriculum')}
          </CardLink>
        </Col>
        <Col xs={6} className="pl-3">
          <CheckInput
            label={t('views.postulation.files.otherCurriculum')}
            name="useApplicationCurriculum"
            control={control}
          />
        </Col>
      </Row>
    </Col>
  );
}
