import { addToast, Icon, ToastProps } from '@octano/global-ui';
import { Fragment, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { uploadDocument } from '../../../request';
import { FileFormField, FileTypes, UploadType } from '../types';
import FileUpload from './FileUpload';

const prefix = 'views.postulation.files.certificationsSection';

export default function CertificateForm() {
  const { t } = useTranslation();
  const { postulationId } = useParams<{ postulationId: string }>();
  const {
    formState: { isSubmitted },
    watch,
    setValue,
  } = useFormContext<FileFormField>();

  const fields = watch('certifications');

  const toast: Record<string, ToastProps> = useMemo(
    () => ({
      error: {
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.onSave.error`),
      },
      success: {
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.onSave.success`),
      },
    }),
    [t],
  );

  const submitFunction = useCallback(
    async (postulationId: string, values: UploadType, index?: number) => {
      let { data, error } = await uploadDocument(postulationId, values);
      if (error) {
        addToast(toast.error);
      } else {
        addToast(toast.success);
        if (typeof index === 'number') {
          setValue(`certifications.${index}.isSelected`, true);
          setValue(`certifications.${index}.fileId`, data.id);
          setValue(`certifications.${index}.url`, data.url);
        }
      }
    },

    [setValue, toast],
  );

  const collegeDegrees = useMemo(
    () => (
      <>
        {fields.map((item, index) => (
          <div key={item.fileId} className="pt-5">
            <FileUpload
              title={item.fileName}
              name={`certifications.${index}.name` as const}
              btnText={t('views.postulation.files.uploadCertifications')}
              error={
                !item.isSelected && isSubmitted
                  ? t('common.validations.required')
                  : undefined
              }
              submitFunction={(values) =>
                submitFunction(
                  postulationId,
                  {
                    reason: FileTypes.ApplicationCertificates,
                    document: values,
                    certificationId: item.certificationId,
                  },
                  index,
                )
              }
            />
            {item.url && (
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                className="text-primary pl-3 mt-1"
                style={{ display: 'flex', minHeight: 47, gap: 8 }}
              >
                <p>{t('views.postulation.review.download')}</p>
                <Icon name="download" className="icon-btn" />
              </a>
            )}
          </div>
        ))}
      </>
    ),
    [fields, isSubmitted, postulationId, submitFunction, t],
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={6}>
          {collegeDegrees}
        </Col>
      </Row>
    </Container>
  );
}
