import { Icon } from "@octano/global-ui";
import { Trans, useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";

type LoadingProps = {
  titleTextBody?: string;
  subTitleTextBody?: string;
  insideCard?: boolean;
  className?: string;
};

const Loading = ({
  titleTextBody,
  subTitleTextBody,
  insideCard = false,
  className,
}: LoadingProps) => {
  const { t } = useTranslation();

  const Content = () => (
    <div className={className} style={{ alignSelf: "center" }}>
      <Row className="text-center py-4 px-3">
        <Col xs={12}>
          <Icon className="spin" name="circle" color="secondary" size="40px" />
        </Col>
        <Col xs={12} className="py-4">
          <span className="fs-20 text-medium">
            <Trans t={t}>{titleTextBody || t(`common.loading.title`)}</Trans>
          </span>
        </Col>
        <Col xs={12}>
          <span className="text-medium">
            <Trans t={t}>
              {subTitleTextBody || t(`common.loading.subtitle`)}
            </Trans>
          </span>
        </Col>
      </Row>
    </div>
  );

  if (insideCard) {
    return (
      <Card className="py-4">
        <Content />
      </Card>
    );
  } else {
    return <Content />;
  }
};

export default Loading;
