import { Button, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row, Modal } from 'reactstrap';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function BackModal({ isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const history = useHistory<number>();

  const goBack = () => {
    history.push('/offer-maintainer/draft-offers');
  };
  return (
    <Modal
      style={{ minWidth: '40%' }}
      isOpen={isOpen}
      centered
      contentClassName="border-0"
    >
      <div className="p-5">
        <div className="d-flex justify-content-center w-100 my-3">
          <Icon name="warning" size={55} color="primary" />
        </div>

        <div className="text-center my-5 px-5 mx-5">
          <p className="fs-22 fw-400 text-dark">
            {t('views.postulation.cancelPostulation.title')}
          </p>
          <p className="fs-18 ,">
            {t('views.postulation.cancelPostulation.description')}
          </p>
        </div>

        <Row>
          <Col xs={12} md={6} lg={6}>
            <Button
              outlined
              text={t(`common.actions.cancel`)}
              fullwidth
              onClick={goBack}
            />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <Button
              type="submit"
              text={t('views.postulation.cancelPostulation.goBack')}
              className="mb-3"
              onClick={onClose}
              fullwidth
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
