import { Button, useMobile } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import BackModal from './BackModal';

enum ModalType {
  None,
  Back,
}

interface Props {
  onSave?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export default function BottomButtons({
  onSave,
  loading = false,
  disabled = false,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState(ModalType.None);

  return (
    <Container fluid>
      <Row>
        <Col className="text-right mt-5">
          <Button
            outlined
            text={t(`common.actions.cancel`)}
            color="danger"
            onClick={() => setOpenedModal(ModalType.Back)}
            style={{ width: isMobile ? '100%' : '155px' }}
            className="mr-4 mb-3"
          />

          <Button
            outlined
            text={t(`common.actions.save`)}
            onClick={onSave}
            style={{ width: isMobile ? '100%' : '155px' }}
            className="mr-4 mb-3"
            disabled={disabled}
          />

          <Button
            type="submit"
            text={t(`common.actions.next`)}
            style={{ width: isMobile ? '100%' : '246px' }}
            loading={loading}
            disabled={disabled}
            className="mb-3"
          />

          <p className="fs-14 mt-3">{t(`views.postulation.infoOnClickNext`)}</p>
        </Col>
      </Row>
      <BackModal
        isOpen={openedModal === ModalType.Back}
        onClose={() => setOpenedModal(ModalType.None)}
      />
    </Container>
  );
}
