import { Box, Button, useMobile } from '@octano/global-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import { useStepState } from '../../hooks/useStepState';
import diffForHumans from '../../utils/time';

interface Props {
  buttonText?: string;
  onBack?: () => void;
  updatedAt?: Date;
}

export default function HeaderSection({
  buttonText,
  onBack,
  updatedAt,
}: Props) {
  const { t } = useTranslation();
  const { prevStep } = useStepState();
  const isMobile = useMobile();

  const backAction = onBack ?? prevStep;

  const timeFromNow = diffForHumans(updatedAt);

  return (
    <Container fluid className="mb-3">
      <Button
        icon="back"
        onClick={backAction}
        outlined
        rounded
        size="sm"
        text={buttonText ?? t(`common.actions.goBackToPreviousStep`)}
        className="mb-4"
      />

      {updatedAt && (
        <Box
          body={timeFromNow}
          color="secondary"
          title={t(`views.postulation.headerSection.updatedAt`)}
          variant="outlined"
          className="float-right"
          fullwidth={isMobile}
        />
      )}
    </Container>
  );
}
