import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
}

export default function SessionExpiredModal({ isOpen, onConfirm }: Props) {
  const { t } = useTranslation();
  const prefix = `views.account.session`;

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center text-center">
        <Icon name="information" size={45} />
        <h1 className="text-dark fs-20 mt-3 mb-4">
          {t(`${prefix}.sessionExpired`)}
        </h1>
        <p className="fs-16 mb-5">
          {t(`${prefix}.sessionExpiredRecomendation`)}
        </p>
        <Button
          text={t(`${prefix}.sessionExpiredBtnText`)}
          onClick={() => onConfirm()}
          fullwidth
          style={{ maxWidth: 284 }}
        ></Button>
      </div>
    </Modal>
  );
}
