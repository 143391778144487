import { Logo } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import PublicFooter from '../components/PublicFooter/PublicFooter';
import RoutesByLayout from '../components/RoutesByLayout/RoutesByLayout';
import { PathsLayouts } from '../config/routes';
import { withAuthCheckRedirection } from '../hocs/withAuthCheckRedirection';
import useUserState from '../hooks/useUserState';
import CustomNavbar, { MenuItem } from '../components/Navbar/Navbar';
import { useMemo } from 'react';

function NavbarLayoutWithAuth() {
  const history = useHistory();
  const { t } = useTranslation();
  const { clearUser } = useUserState();

  const downloadUserGuide = () => {
    const url = window?.location?.origin + '/tenant/manual-postulante.pdf';
    window?.open(url, '_blank')?.focus();
  };

  const loggedMenuOptions = useMemo<MenuItem[]>(
    () => [
      {
        icon: 'user',
        text: t(`common.terms.userProfile`),
        onClick: () => {
          history.push('/profile/edit');
        },
      },
      {
        icon: 'offers',
        text: t(`common.terms.myPostulations`),
        onClick: () => {
          history.push('/postulations');
        },
      },
      {
        icon: 'padlock',
        text: t(`common.terms.changePassword`),
        onClick: () => {
          history.push('/profile/password');
        },
      },
      {
        icon: 'academic_offer',
        text: t(`common.terms.userGuide`),
        onClick: downloadUserGuide,
      },
      {
        icon: 'log_out',
        text: t(`common.terms.logOut`),
        onClick: () => {
          clearUser();
          history.push('/');
        },
      },
    ],
    [clearUser, history, t],
  );

  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      <CustomNavbar
        logo={<Logo alt="logo" src="/tenant/logo_white.svg" fallbackSrc="" />}
        menuItems={loggedMenuOptions}
      />
      <Container fluid className="flex-grow-1">
        <RoutesByLayout />
      </Container>
      <PublicFooter />
    </div>
  );
}

export default withAuthCheckRedirection({
  component: NavbarLayoutWithAuth,
  calculateRedirectTo: ({ isLogged, userData }) => {
    if (!isLogged && !userData) {
      return PathsLayouts.auth;
    }
  },
});
