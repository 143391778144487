import { useCallback } from 'react';

// Components
import Loading from '../../../components/info/Loading';
import DisplayError from '../../../components/info/DisplayError';
import { Button, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import { useGetPostulationDetailQuery } from '../api';
import { useHistory, useParams } from 'react-router-dom';

// I18n
import { useTranslation } from 'react-i18next';
import Section from '../../../components/Section/Section';
import File from './parts/File';

// Types
type PostulationDetailScreenParams = {
  postulationId: string;
};

// Render
const PostulationDetailScreen = () => {
  const { t } = useTranslation();

  const isMobile = useMobile();
  const history = useHistory();
  const { postulationId } = useParams<PostulationDetailScreenParams>();

  const {
    data: postulation,
    isFetching,
    error,
  } = useGetPostulationDetailQuery(postulationId, {
    refetchOnMountOrArgChange: true,
  });

  const goBackToList = useCallback(() => {
    history.push('/postulations');
  }, [history]);

  if (error) {
    return <DisplayError typeError={(error as any)?.status} />;
  }

  return (
    <Row className={clsx('py-5', isMobile ? 'px-4' : 'px-5 mx-3')}>
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <Row>
          <Col className="mb-4" xs={12}>
            <Button
              className="mb-3"
              icon="back"
              onClick={goBackToList}
              outlined
              rounded
              size="sm"
              text={t('views.postulations.goBackToList')}
            />
          </Col>
          <Col xs={12} className="mb-4">
            <p className="text-primary fs-30 fw-700 mb-3 text-uppercase">
              {t('views.postulations.review.summary')}
            </p>
          </Col>

          {!isFetching && (
            <>
              <Section
                title={t('views.postulations.review.educationalLevel')}
                description={postulation?.educationalLevel || []}
              />
              <Section
                title={t('views.postulations.review.salaryPretension')}
                description={`${postulation?.salaryPretension}`}
                isVisible={Boolean(postulation?.salaryPretension)}
              />
              <Section
                title={
                  t('views.postulations.review.startAvailability') + 'qweqw'
                }
                description={postulation?.availabilityDate}
                isVisible={typeof postulation?.availabilityDate === 'string'}
              />
              <Section
                title={t('views.postulations.review.scheduleAvailability')}
                description={postulation?.availabilityTime}
              />

              <Section
                title={t(
                  'views.postulations.review.hasAdministrativeInabilities',
                )}
                subtitle={t(
                  'views.postulations.review.hasAdministrativeInabilitiesSubtitle',
                )}
                description={
                  postulation?.hasAdministrativeInabilities
                    ? t('views.postulations.review.yes')
                    : t('views.postulations.review.no')
                }
              />
              <Section
                title={t(
                  'views.postulations.review.hasInstitutionalInabilities',
                )}
                subtitle={t(
                  'views.postulations.review.hasInstitutionalInabilitiesSubtitle',
                )}
                description={
                  postulation?.hasInstitutionalInabilities
                    ? t('views.postulations.review.yes')
                    : t('views.postulations.review.no')
                }
              />

              {Boolean(postulation?.answer?.length) && (
                <Section
                  title={t('views.postulations.review.questions')}
                  answer={postulation?.answer}
                />
              )}
              {postulation?.laws?.map((l) => (
                <Section
                  title={l.name}
                  subtitle={l.question}
                  description={
                    l.answer
                      ? 'Confirmo haber leído y estar de acuerdo.'
                      : 'No confirmo haber leído y estar de acuerdo.'
                  }
                />
              ))}
            </>
          )}
        </Row>

        {!!isFetching && <Loading />}
        {!isFetching && (
          <>
            {!!postulation?.curriculum?.length && (
              <Row>
                <Col xs={12} sm={7} className="mb-4">
                  <File
                    {...postulation?.curriculum[0]}
                    documentName={'CURRICULUM'}
                  />
                </Col>
              </Row>
            )}
            {Boolean(postulation?.collegeDegrees?.length) && (
              <Row>
                <Col xs={12} sm={7}>
                  <p className="text-primary fs-20 fw-700 mb-3 text-uppercase">
                    {t('views.postulations.review.collegeDegrees')}
                  </p>
                </Col>
                {postulation?.collegeDegrees?.map((c, i) => (
                  <Col xs={12} sm={7} key={`col-${i}`} className="mb-4">
                    <File
                      name={c.name}
                      url={c.url}
                      documentName={c.documentName!}
                    />
                  </Col>
                ))}
              </Row>
            )}
            {Boolean(postulation?.certifications?.length) && (
              <Row>
                {postulation?.certifications?.map((c, i) => (
                  <Col xs={12} sm={7} key={`col-${i}`} className="mb-4">
                    <File
                      name={c.name}
                      url={c.url}
                      documentName={c.documentName!}
                    />
                  </Col>
                ))}
              </Row>
            )}
            {Boolean(postulation?.licenses?.length) && (
              <Row>
                {postulation?.licenses?.map((c, i) => (
                  <Col xs={12} sm={7} key={`col-${i}`} className="mb-4">
                    <File
                      name={c.name}
                      url={c.url}
                      documentName={c.documentName!}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </>
        )}

        <Row>
          <Col xs={12} className="text-right mt-5">
            <Button
              text={t('views.postulations.goBackToList')}
              style={{ width: isMobile ? '100%' : '246px' }}
              className="mb-3"
              onClick={goBackToList}
            />
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default PostulationDetailScreen;
