// Hooks
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

// Utils
import { mapNameAndIdToLabelAndValue } from '../../../../utils/mapNameAndIdToLabelAndValue';

// Redux
import {
  useGetComunnesQuery,
  useGetRegistrationFormDataQuery,
} from '../../api';

// Types
import {
  BaseRegisterFormFields,
  RegisterFormFields,
  RegisterFormFile,
} from '../types';
import PersonalInformation from './PersonalInformation';
import AcademicInformation from './AcademicInformation';
import ProfessionalInformation from './ProfessionalInformation';
import FileUpload from './FileUpload';
import { useLocation } from 'react-router-dom';

export type RegisterFormProps = {
  isEditForm?: boolean;
};

// Render
const RegisterForm = ({ isEditForm = false }: RegisterFormProps) => {
  const {
    control,
    unregister,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useFormContext<RegisterFormFields>();

  const { state = {} as any } = useLocation<{
    form: BaseRegisterFormFields;
    files: RegisterFormFile;
  }>();

  const [region] = watch(['region']);

  const { data: formData } = useGetRegistrationFormDataQuery();

  const { data: communesData } = useGetComunnesQuery(region?.value, {
    skip: !region?.value,
    refetchOnMountOrArgChange: true,
  });

  const countries = useMemo(
    () => formData?.countries?.map(mapNameAndIdToLabelAndValue) || [],
    [formData?.countries],
  );

  const regions = useMemo(
    () => formData?.regions?.map(mapNameAndIdToLabelAndValue) || [],
    [formData?.regions],
  );

  const educationalLevels = useMemo(
    () => formData?.educationalLevels?.map(mapNameAndIdToLabelAndValue) || [],
    [formData?.educationalLevels],
  );

  const collegeDegrees = useMemo(
    () => formData?.collegeDegrees?.map(mapNameAndIdToLabelAndValue) || [],
    [formData?.collegeDegrees],
  );

  const communes = useMemo(
    () => communesData?.map(mapNameAndIdToLabelAndValue) || [],
    [communesData],
  );

  //permite recuperar la informaciòn al volver al paso anterior
  useEffect(() => {
    if (!isEditForm && state?.form && state?.files) {
      reset({
        ...state.form,
        curriculum: state.files.curriculum,
        additionalFile: state.files.additionalFile,
      });
    }
  }, [isEditForm, reset, state.files, state.form]);

  useEffect(() => {
    if (region) {
      setValue('commune', null);
    } else {
      setValue('commune', null);
    }
  }, [region, setValue]);

  return (
    <>
      <PersonalInformation
        isEditForm={isEditForm}
        control={control}
        watch={watch}
        setValue={setValue}
        unregister={unregister}
        countries={countries}
        regions={regions}
        communes={communes}
      />
      <AcademicInformation
        control={control}
        watch={watch}
        educationalLevels={educationalLevels}
        collegeDegrees={collegeDegrees}
      />
      <ProfessionalInformation control={control} />
      <FileUpload
        watch={watch}
        control={control as any}
        isSubmitting={isSubmitting}
        downloadEnabled
      />
    </>
  );
};

export default RegisterForm;
