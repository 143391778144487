import { Container, Col } from 'reactstrap';
import { Logo } from '@octano/global-ui';

export default function PublicFooter() {
  return (
    <Container fluid className="mt-4 publicFooter">
      <Col xs={12}></Col>
      <Logo
        className="publicFooter-icon mt-2"
        alt="logo"
        src="/tenant/logo_white.svg"
        fallbackSrc=""
      />
    </Container>
  );
}
