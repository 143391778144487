import { TextAreaInput } from '@octano/global-ui';
import React, { Fragment, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { ResumeFormField } from '../types';
import LengthCounter from '../../../../../components/Form/LengthCounter';
import { TEXTAREA_MAXLENGTH } from '../../../../../config/constants';

export default function Questions() {
  const { t } = useTranslation();
  const { control } = useFormContext<ResumeFormField>();
  const { fields } = useFieldArray({
    control,
    name: 'postulationAnswer',
  });

  const questionsList = useMemo(
    () => (
      <>
        {fields.map((item, i) => (
          <Fragment key={item.id}>
            <Col xs={12}>
              <p className="fs-20 fw-700 text-primary">{item.name}</p>
            </Col>
            <Col xs={12} className="mb-4">
              <TextAreaInput
                label=""
                placeholder={t('views.account.registration.form.description')}
                name={`postulationAnswer.${i}.answer` as const}
                control={control}
                rules={{ required: t('common.validations.required') }}
                maxLength={TEXTAREA_MAXLENGTH}
              />
              <LengthCounter
                name={`postulationAnswer.${i}.answer` as const}
                maxLength={TEXTAREA_MAXLENGTH}
              />
            </Col>
          </Fragment>
        ))}
      </>
    ),
    [control, fields, t],
  );

  return (
    <>
      {fields.length > 0 && (
        <>
          <Col xs={12}>
            <p className="fs-20 fw-700 text-primary">
              PREGUNTAS SOBRE EL CARGO
            </p>
          </Col>
          {questionsList}
        </>
      )}
    </>
  );
}
