import { FormRequest } from '../../types/registrationTypes';
import { REGISTRATION } from '../endpoints';
import request from '../request';

export const createAccount = (data: any) => {
  return request(REGISTRATION.CREATE_ACCOUNT, {
    method: 'POST',
    data,
  });
};

export const uploadFile = (userId: number, name: string, file: File) => {
  const data = new FormData();
  data.append(name, file, file.name);

  return request(REGISTRATION.UPLOAD_FILE(userId), {
    method: 'POST',
    data,
  });
};

export const getRegistrationFormData = () => {
  return request<FormRequest>(REGISTRATION.GET_FORM_DATA, {
    method: 'GET',
  });
};
