import dayjs from 'dayjs';
import { COMMIT_ID, COMMIT_TIMESTAMP } from '../../config/constants';
import { useTranslation } from 'react-i18next';

const AppVersion = () => {
  const { t } = useTranslation();
  const prefix = 'common.terms';

  if (COMMIT_TIMESTAMP && COMMIT_ID) {
    const commitDate = new Date(COMMIT_TIMESTAMP * 1000);
    return (
      <div className="box-app-version">
        {t(`${prefix}.version`)}: {COMMIT_ID}
        <br />
        {t(`${prefix}.date`)}: {dayjs(commitDate).format('DD-MM-YYYY HH:mm')}
      </div>
    );
  } else {
    return null;
  }
};

export default AppVersion;
