// Components
import JobList from './parts/JobList';
import Filters from './parts/Filters';
import PrivateAlert from './parts/PrivateAlert';
import clsx from 'clsx';
import { Row } from 'reactstrap';

// Hooks
import { useMobile } from '@octano/global-ui';

// Render
const JobListScreen = () => {
  const isMobile = useMobile();

  return (
    <Row className={clsx('py-5', isMobile ? 'px-4' : 'px-5 mx-3')}>
      <PrivateAlert />
      <Filters />
      <JobList />
    </Row>
  );
};

export default JobListScreen;
