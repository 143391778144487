import { TextOutlinedInput } from '@octano/global-ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { formatToOnlyPositiveIntegers } from '../../../../../utils/formatter';
import { ResumeFormField } from '../types';

interface Props {
  isVisible: boolean;
}
export default function Salary({ isVisible }: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext<ResumeFormField>();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Col xs={12}>
        <p className="fs-20 fw-700 text-primary">
          {t('views.postulation.resume.salarySection.title')}
        </p>
      </Col>
      <Col xs={12} className="mb-4">
        <TextOutlinedInput
          label=""
          placeholder={t(
            'views.postulation.resume.salarySection.salaryPretension',
          )}
          name="salaryPretension"
          formatter={formatToOnlyPositiveIntegers}
          control={control}
          rules={{ required: t('common.validations.required') }}
        />
      </Col>
    </>
  );
}
