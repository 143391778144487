import { Steps } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';

import Loading from '../../components/info/Loading';
import { PathsLayouts } from '../../config/routes';
import withBasicProvider from '../../hocs/withBasicProvider';
import { useApplicationSteps } from '../../hooks/useApplicationSteps';
import { useStepState } from '../../hooks/useStepState';
import StepProvider from '../../providers/StepProvider';
import {
  CurrentSteps,
  isCurrentStepType,
  NoCurrentStep,
} from '../../types/stepsTypes';
import { getCurrentStep } from './request';

const NO_STEPS = -1;

// Reemplazar por un hook que detecte el paso y se encarge del enrutado
const PATH = (offerId: number | string, postulationId: number | string) =>
  `/postulation/offer/${offerId}/apply/${postulationId}`;

function Postulation() {
  const history = useHistory();

  const { namesSteps, CurrentStepComponent, getIndexStep } =
    useApplicationSteps();

  const { currentStep, setCurrentStep } = useStepState();

  const { offerId } = useParams<{ offerId: string }>();

  const [postulationId, setPostulationId] = useState<number>();

  /**
   * si finalizó su proceso de postulación el usuario es forzado volver atrás
   * por tanto el proceso de postulación no se cargará
   */
  const checkStep = useCallback(
    (step: CurrentSteps) =>
      ![
        CurrentSteps.FileUpload,
        CurrentSteps.Resume,
        NoCurrentStep.None,
      ].includes(step) && history.goBack(),
    [history],
  );

  const getCurrentStepRequest = useCallback(async () => {
    const { data } = await getCurrentStep(Number(offerId));

    if (data) {
      checkStep(data.currentStep);
      const alreadyApplied = isCurrentStepType(data.currentStep);
      const step = alreadyApplied ? data.currentStep : 'resume';
      setCurrentStep(getIndexStep(step), alreadyApplied);
      setPostulationId(data.id);
    }
  }, [checkStep, getIndexStep, offerId, setCurrentStep]);

  // Reemplazar por un hook que detecte el paso y se encarge del enrutado
  useEffect(() => {
    if (!offerId) {
      history.replace(PathsLayouts.public);
    }

    if (postulationId) {
      history.replace(PATH(offerId, postulationId));
    }
  }, [history, offerId, postulationId]);

  useEffect(() => {
    getCurrentStepRequest();
  }, [getCurrentStepRequest]);

  return (
    <>
      <div className="w-100 my-4 p-0">
        <Steps currentStep={currentStep} steps={namesSteps} color="secondary" />
      </div>
      <Card className="p-4">
        {currentStep === NO_STEPS ? <Loading /> : <CurrentStepComponent />}
      </Card>
    </>
  );
}
export default withBasicProvider(StepProvider)(Postulation);
