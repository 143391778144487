import { CheckInput } from '@octano/global-ui';
import React, { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, CardLink } from 'reactstrap';
import { ResumeFormField } from '../types';
import { useTranslation } from 'react-i18next';

export default function Laws() {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<ResumeFormField>();
  const fields = watch('postulationLaws');

  const someNotSelected = useMemo(
    () => fields.some((p) => !p.isSelected),
    [fields],
  );

  const LawsList = useMemo(
    () =>
      fields.map((item, i) => (
        <Fragment key={i}>
          <Col xs={12} className="mb-4">
            <p className="fs-20 fw-700 text-primary">{item.name}</p>
            <div className="d-flex">
              <p className="fs-18">{item.ask}</p>
              <CardLink
                className="ml-4 fs-18"
                onClick={() =>
                  setValue(`postulationLaws.${i}.open` as const, !item.open)
                }
              >
                ¿Que significa esto?
              </CardLink>
            </div>
            {item.open && (
              <p
                style={{ whiteSpace: 'pre-line' }}
                className="fs-16 fw-400 text-light mb-4"
              >
                {item.explanation}
              </p>
            )}
            <CheckInput
              label={'Confirmo haber leído y estar de acuerdo.'}
              name={`postulationLaws.${i}.isSelected` as const}
              control={control}
              rules={{
                validate: {
                  conditional: () => {
                    if (someNotSelected) {
                      return t('common.validations.required');
                    }
                    return undefined;
                  },
                },
              }}
            />
          </Col>
        </Fragment>
      )),
    [control, fields, setValue, someNotSelected, t],
  );

  return <>{LawsList}</>;
}
