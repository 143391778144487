export const AUTH = {
  SEND_ACTIVATION_EMAIL: '/manage-account/send-activation-mail',
  CHECK_ACTIVATION_TOKEN: '/manage-account/check-token-validity',
  ACTIVATE_ACCOUNT: '/manage-account/activate',
  LOGIN: '/auth/login',
  SEND_PASSWORD_RECOVERY_MAIL: '/recovery-password/send',
  VALIDATE_RECOERY_TOKEN: '/recovery-password/validate',
  PASSWORD_CHANGE: '/recovery-password/restore',
};

export const REGISTRATION = {
  GET_FORM_DATA: '/manage-account/registration-form-data',
  CREATE_ACCOUNT: '/manage-account/create-account',
  UPLOAD_FILE: (userId: number | string) =>
    `/manage-account/file-upload/${userId}`,
};

export const OFFERS = {
  LIST: `/public-offers`,
  FILTERS: '/public-offers/form',
  PUBLIC_DETAIL: (offerId: number | string) => `/public-offers/${offerId}`,
  DETAIL: (offerId: number | string) => `/offers/${offerId}`,
};

export const POSTULATION = {
  CURRENT_STEP: (offerId: number | string) => `apply/current-step/${offerId}`,
  RESUME: {
    GET_FORM_DATA: (offerId: number | string) => `apply/resume/${offerId}`,
    SAVE: (offerId: number | string) => `apply/resume/${offerId}`,
  },
  FILES: {
    GET_FORM_DATA: (offerId: number | string) => `apply/files/${offerId}`,
    SAVE: (postulationId: number | string) => `apply/files/${postulationId}`,
    UPLOAD_DOCUMENT: (postulationId: number | string) =>
      `apply/files/${postulationId}/upload-file`,
  },
  REVIEW: {
    GET_FORM_DATA: (postulationId: number | string) =>
      `apply/review/${postulationId}`,
    SAVE: (postulationId: number | string) => `apply/review/${postulationId}`,
  },
};
