import { configureStore } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';

// Services
import { offersApi } from '../views/jobsList/api';
import { privateOffersApi } from '../views/private-offers/api';
import { postulationsApi } from '../views/postulations/api';
import { accountApi, fileApi } from '../views/account/api';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [accountApi.reducerPath]: accountApi.reducer,
    [offersApi.reducerPath]: offersApi.reducer,
    [privateOffersApi.reducerPath]: privateOffersApi.reducer,
    [postulationsApi.reducerPath]: postulationsApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(accountApi.middleware)
      .concat(offersApi.middleware)
      .concat(privateOffersApi.middleware)
      .concat(postulationsApi.middleware)
      .concat(fileApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
