import { useEffect, useState } from 'react';

const useScrollPosition = ({ scrollY }: { scrollY: number }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);

      if (window.scrollY > scrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, [scrollY]);

  return { scrollPosition, show };
};
export default useScrollPosition;
