import { POSTULATION } from '../../api/endpoints';
import request from '../../api/request';
import { CurrentStep } from '../../types/stepsTypes';
import toFormData from '../../utils/form';
import { FilesRequest, UploadType } from './steps/files/types';
import { ResumRequest } from './steps/resume/types';
import { ReviewRequest } from './steps/review/types';

export const getCurrentStep = (offerId: number) => {
  return request<CurrentStep>(POSTULATION.CURRENT_STEP(offerId), {
    method: 'GET',
  });
};

export const resumeFormData = (offerId: number) => {
  return request<ResumRequest>(POSTULATION.RESUME.GET_FORM_DATA(offerId), {
    method: 'GET',
  });
};

export const saveResumeData = (offerId: number, data: any) => {
  return request<{ id: number; currenStep: string }>(
    POSTULATION.RESUME.SAVE(offerId),
    {
      method: 'POST',
      data,
    },
  );
};

export const fielesFormData = (offerId: number | string) => {
  return request<FilesRequest>(POSTULATION.FILES.GET_FORM_DATA(offerId), {
    method: 'GET',
  });
};

export const savefilesData = (postulationId: number | string, data: any) => {
  return request<{ id: number; currenStep: string }>(
    POSTULATION.FILES.SAVE(postulationId),
    {
      method: 'POST',
      data,
    },
  );
};

export async function uploadDocument(
  postulationId: number | string,
  values: UploadType,
) {
  return request(POSTULATION.FILES.UPLOAD_DOCUMENT(postulationId), {
    method: 'POST',
    data: toFormData(values),
  });
}

export const review = (postulationId: number | string) => {
  return request<ReviewRequest>(
    POSTULATION.REVIEW.GET_FORM_DATA(postulationId),
    {
      method: 'GET',
    },
  );
};

export const saveReview = (postulationId: number | string) => {
  return request<{ id: number; currenStep: string }>(
    POSTULATION.REVIEW.SAVE(postulationId),
    {
      method: 'POST',
    },
  );
};
