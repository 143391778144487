import { addToast, OutlinedSelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Row } from 'reactstrap';

import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import BottomButtons from '../../../../components/steps/BottomButtons';
import HeaderSection from '../../../../components/steps/HeaderSection';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useStepState } from '../../../../hooks/useStepState';
import { mapNameAndIdToLabelAndValue } from '../../../../utils/mapNameAndIdToLabelAndValue';
import { resumeFormData, saveResumeData } from '../../request';
import AcademicInformation from './parts/AcademicInformation';
import AvailabilityDate from './parts/AvailabilityDate';
import AvailabilityTime from './parts/AvailabilityTime';
import Description from './parts/Description';
import Languages from './parts/Languages';
import Questions from './parts/Questions';
import Salary from './parts/Salary';
import Inabilities from './parts/Inabilities';
import {
  defaultValuesFromRequest,
  simulateEducationalInformationRequest,
  valuesToSubmit,
} from './parts/utils';
import { ResumeFormField, ResumRequest } from './types';
import Laws from './parts/Laws';

enum RequestEnum {
  AvailabilityTime = 'availability-time',
  AvailabilityDate = 'availability-date',
  Attachment = 'attachment',
  Pretension = 'pretension',
  AdministrativeInabilities = 'administrative-inabilities',
  InstitutionalInabilities = 'institutional-inabilities',
}

const defaultValues: ResumRequest = {
  name: '',
  campus: { name: '' },
  workingDay: { name: '' },
  modality: { name: '' },
  educations: [],
  competencies: [],
  requirements: [],
  requests: [],
  languageLevels: [],
  collegeDegrees: [],
  educationalLevels: [],
  questions: [],
  laws: [],
  description: '',
  knowledge: '',
  updatedAt: new Date(),
  educationalInformation: [],
  offerLanguageLevels: [],
  hasAdministrativeInabilities: null,
  hasInstitutionalInabilities: null,
};

const keyPrefix = 'views.postulation';

export default function Resume() {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix });
  const { nextStep } = useStepState();
  const methods = useForm<ResumeFormField>();
  const { offerId } = useParams<{ offerId: string }>();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    setValue,
  } = methods;

  const [resume, setResume] = useState<ResumRequest>(defaultValues);

  const { educationalInformation, name: title } = resume;

  const educationalLevels = useMemo<OutlinedSelectOptionType[]>(
    () => resume.educationalLevels.map(mapNameAndIdToLabelAndValue),
    [resume.educationalLevels],
  );

  const collegeDegrees = useMemo<OutlinedSelectOptionType[]>(
    () => resume.collegeDegrees.map(mapNameAndIdToLabelAndValue),
    [resume.collegeDegrees],
  );

  const requests = useMemo<string[]>(
    () =>
      resume.requests
        .filter((r) => r.shortName)
        .map((r) => r.shortName as string),
    [resume.requests],
  );

  const getData = useCallback(async () => {
    setLoading(true);
    const { data, error } = await resumeFormData(parseInt(offerId));
    if (error) {
      setErrorLoading(error.code);
    } else {
      setResume(data);
      reset(defaultValuesFromRequest(data));
    }
    setLoading(false);
  }, [setLoading, offerId, setErrorLoading, reset]);

  const onSubmit = useCallback(
    async (next = false) => {
      const formData = valuesToSubmit(getValues());
      const { error, data } = await saveResumeData(parseInt(offerId), formData);
      const educationalList = simulateEducationalInformationRequest(
        getValues().educationalInformation,
      );
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`onSave.error`),
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`onSave.success`),
        });

        setValue('educationalInformation', []);
        setResume((old) => ({
          ...old,
          educationalInformation: [
            ...old.educationalInformation,
            ...educationalList,
          ],
        }));
        if (next) {
          history.replace(`/postulation/offer/${offerId}/apply/${data.id}`);
          nextStep();
        }
      }
    },
    [getValues, history, nextStep, offerId, setValue, t],
  );

  const onSave = () => {
    clearErrors();
    onSubmit();
  };

  const goBack = () => history.goBack();

  useEffect(() => {
    getData();
  }, [getData]);

  if (errorLoading) {
    return <DisplayError typeError={errorLoading} />;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(() => onSubmit(true))}>
        <Row>
          <HeaderSection updatedAt={resume.updatedAt} onBack={goBack} />
          <Description {...{ resume, title }} />
          <AcademicInformation
            {...{
              collegeDegrees,
              educationalLevels,
              educationalInformation,
            }}
          />
          <AvailabilityDate
            isVisible={requests.includes(RequestEnum.AvailabilityDate)}
          />

          <AvailabilityTime
            isVisible={requests.includes(RequestEnum.AvailabilityTime)}
          />
          <Salary isVisible={requests.includes(RequestEnum.Pretension)} />
          <Laws />
          <Languages />
          <Questions />
          <Inabilities
            {...{
              name: 'hasAdministrativeInabilities',
              title: t('resume.inabilitiesSection.administrative.title'),
              description: t(
                'resume.inabilitiesSection.administrative.description',
              ),
              whatIs: t('resume.inabilitiesSection.whatIs'),
              explanation: (
                <Trans i18nKey="views.postulation.resume.inabilitiesSection.administrative.explanation" />
              ),
              ask: t('resume.inabilitiesSection.administrative.ask'),
              control,
              rules: { required: true },
              isVisible: requests.includes(
                RequestEnum.AdministrativeInabilities,
              ),
            }}
          />
          <Inabilities
            {...{
              name: 'hasInstitutionalInabilities',
              title: t('resume.inabilitiesSection.institutional.title'),
              description: t(
                'resume.inabilitiesSection.institutional.description',
              ),
              ask: t('resume.inabilitiesSection.institutional.ask'),
              explanation: (
                <Trans i18nKey="views.postulation.resume.inabilitiesSection.institutional.explanation" />
              ),
              control,
              rules: { required: true },
              isVisible: requests.includes(
                RequestEnum.InstitutionalInabilities,
              ),
            }}
          />
          <BottomButtons
            onSave={onSave}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </Row>
      </Form>
    </FormProvider>
  );
}
