export const JWT_KEY: string =
  process.env.REACT_APP_CAMPUS_JWT_KEY || 'G3000_CAMPUS_JWT_KEY';
export const COMMIT_ID: string | null = process.env.REACT_APP_COMMIT_ID || null;
export const COMMIT_TIMESTAMP: number | null = process.env
  .REACT_APP_COMMIT_TIMESTAMP
  ? parseInt(process.env.REACT_APP_COMMIT_TIMESTAMP)
  : null;
export const CONTACT_EMAIL: string =
  process.env.REACT_APP_CONTACT_EMAIL || 'soporte@ozmo.cl';
export const SECTIONS_AND_PACAGES_UPLOAD_STRUCTURE_DOCUMENT =
  'PlantillaCargaPaqueteSecciones.xlsx';
export const SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT =
  'PlantillaCargaSeccionesAtributos.xlsx';
export const RECAPTCHA_SITE_KEY: string =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
export const TEXTAREA_MAXLENGTH: number = parseInt(
  process.env.REACT_APP_TEXTAREA_MAXLENGTH ?? '300',
);
export const TEXTINPUT_MAXLENGTH: number = parseInt(
  process.env.REACT_APP_TEXTINPUT_MAXLENGTH ?? '255',
);
