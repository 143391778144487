import { Box, Button, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { Benefit, StatusOffer, OfferStatus } from '../../../types/jobOffer';

interface JobDetailProps {
  name: string;
  description: string;
  campus?: string;
  career: string;
  working_day?: string;
  modality?: string;
  period?: string;
  subjectHours?: number;
  benefits?: Benefit[];
  offerId?: number;
  updatedAt?: string;
  status: StatusOffer;
  hasApplication: boolean;
  isPrivate?: boolean;
  onClick: () => void;
  onReject?: () => void;
}

export default function JobDetailHeader({
  name,
  campus,
  career,
  working_day,
  modality,
  period,
  subjectHours,
  updatedAt,
  status,
  hasApplication,
  isPrivate,
  onClick,
  onReject,
}: JobDetailProps) {
  const prefix = 'views.jobsDetail';
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMobile();

  const timeFromNow = dayjs(updatedAt)
    .fromNow()
    .replace(/^./, dayjs(updatedAt).fromNow()[0].toUpperCase());

  const goBackToList = () => {
    history.push('/public/jobs');
  };

  return (
    <>
      <Col xs={12}>
        <Row>
          <Col xs={12} sm={6}>
            <Button
              className="mb-3"
              icon="back"
              onClick={goBackToList}
              outlined
              rounded
              size="sm"
              text={t(`${prefix}.goBack`)}
            />
          </Col>
          <Col xs={12} sm={6}>
            {status.id === 1 && !hasApplication && (
              <Box
                className="float-right"
                body={timeFromNow}
                color="secondary"
                title={t(`${prefix}.updateDate`)}
                variant="outlined"
                fullwidth={isMobile}
              />
            )}
            {hasApplication && (
              <Box
                className="float-right border-danger text-danger align-items-center mb-4"
                body=""
                title={t(`${prefix}.youAlreadyApplied`)}
                variant="outlined"
                fullwidth={isMobile}
              />
            )}

            {![OfferStatus.Available, OfferStatus.InProgress].includes(
              status.id!,
            ) && (
              <Box
                className="border-danger text-danger align-items-center float-right text-danger mx-md-5"
                body={''}
                color="default"
                title={t(`${prefix}.notValid`)}
                variant="outlined"
                fullwidth={isMobile}
              />
            )}
          </Col>
        </Row>
      </Col>

      <Col xs={12} className="mt-4">
        <h3
          className={clsx(
            'fw-700 text-primary mb-4',
            isMobile ? 'fs-25' : 'fs-30',
          )}
        >
          {name}
        </h3>
        <ul className="p-0 pl-4">
          <li className={'fw-600 fs-20'}>{career}</li>
          <li className={'fw-600 fs-20'}>
            {t(`common.terms.campus`)} {campus}
          </li>
          <li className={'fw-600 fs-20'}>
            {t(`common.terms.schedule`)} {working_day}
          </li>
          <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
            {t(`common.terms.modality`)} {modality}
          </li>
          <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
            {t(`common.terms.subjectHours`)}: {subjectHours}
          </li>
          <li className={clsx('fw-600 fs-20', isMobile && 'mb-4')}>
            {t(`common.terms.period`)} {period}
          </li>
        </ul>
      </Col>

      {[OfferStatus.Available, OfferStatus.InProgress].includes(status.id!) &&
        !hasApplication && (
          <Col xs={12} className="pt-4 pb-4">
            <div className="d-flex flex-row wrap">
              <Button
                className="w-100"
                onClick={onClick}
                text={t(`${prefix}.applyNow`)}
                style={{ maxWidth: isMobile ? '100%' : '304px' }}
              />
              {!!isPrivate && (
                <Button
                  className="ml-2 w-100"
                  color="danger"
                  onClick={onReject}
                  text={t(`${prefix}.rejectOffer`)}
                  style={{ maxWidth: isMobile ? '100%' : '304px' }}
                  outlined
                />
              )}
            </div>
          </Col>
        )}
    </>
  );
}
