import {
  Icon,
  IconNameType,
  Logo,
  SingleCardContainer,
} from '@octano/global-ui';
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

interface Props {
  icon?: IconNameType;
  title?: string;
  subTitle?: string;
  children: JSX.Element;
  hasLinkUndercard?: boolean;
  wrapped?: boolean;
  showLogo?: boolean;
}

export default function AccountContainer({
  icon,
  title,
  subTitle,
  children,
  hasLinkUndercard,
  wrapped,
  showLogo = true,
}: Props) {
  const content = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          {showLogo && (
            <Col
              xs={12}
              className="text-center py-5 text-primary"
              style={{ borderBottom: '1px solid' }}
            >
              <Logo
                alt="logo"
                style={{ maxHeight: 80 }}
                src="/tenant/logo.svg"
                fallbackSrc="/tenant/logo.png"
              />
            </Col>
          )}
          {icon && (
            <Col xs={12} className="d-flex justify-content-center mt-5">
              <Icon name={icon} key={icon} size="55px" />
            </Col>
          )}
          {title && (
            <Col xs={12} className="mt-4 d-flex justify-content-center">
              <p className="text-center text-dark fs-20">{title}</p>
            </Col>
          )}
          {subTitle && (
            <Col xs={12} className="mb-4 d-flex justify-content-center">
              <p className="text-center mb-0 fs-18">{subTitle}</p>
            </Col>
          )}
        </Row>
        {children}
      </React.Fragment>
    );
  }, [showLogo, icon, title, subTitle, children]);

  return (
    <React.Fragment>
      {wrapped ? (
        <SingleCardContainer>{content}</SingleCardContainer>
      ) : (
        <div>{content}</div>
      )}
    </React.Fragment>
  );
}
