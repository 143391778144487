import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, FormText } from 'reactstrap';
import { useState } from 'react';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  title: string;
  description: string;
  whatIs?: string;
  ask?: string;
  explanation: string | React.ReactElement;
  isVisible: boolean;
}
export default function Inabilities<T extends FieldValues>(props: Props<T>) {
  const { t } = useTranslation();

  const { field, fieldState } = useController<T>(
    props.isVisible ? props : { ...props, rules: { required: false } },
  );

  const { isVisible, title, description, ask, whatIs, explanation } = {
    ...field,
    ...props,
  };

  const radioOptions = [
    { label: 'Si', value: String(true) },
    { label: 'No', value: String(false) },
  ];

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {isVisible && (
        <>
          <Col xs={12}>
            <p className="fs-20 fw-700 text-primary">{title}</p>
            <p className="fs-18 fw-400 text-light">
              {description}
              <span
                className="ml-2 cursor-pointer text-link"
                onClick={() => setOpen((p) => !p)}
              >
                {whatIs}
              </span>
            </p>
            {!!open && (
              <p className="fs-16 fw-400 text-light mb-4">{explanation}</p>
            )}
          </Col>
          <Col xs={12} className="mb-4">
            <p className="fs-16 fw-700 text-medium">{ask}</p>

            <FormGroup className="d-inline-flex w-100" tag="fieldset">
              <FormGroup className="pl-0" check>
                {radioOptions.map(({ label, value }) => (
                  <>
                    <Label className="g-checkbox-input-container pr-4" check>
                      <input
                        className="g-checkbox-input"
                        {...field}
                        type="radio"
                        value={value}
                        checked={field.value === value}
                      />
                      <span className="checkmark"></span>
                      {label}
                    </Label>
                  </>
                ))}
                <FormText className="g-input-error" color="danger">
                  {Boolean(fieldState?.error) &&
                    t('views.postulation.resume.inabilitiesSection.required')}
                </FormText>
              </FormGroup>
            </FormGroup>
          </Col>
        </>
      )}
    </>
  );
}
