import { OfferDetail } from '../../types/jobOffer';
import { FileTypes } from '../postulation/steps/files/types';
import { Answer, Law } from '../postulation/steps/review/types';

export enum AuthorizationStatus {
  //postulacion autorizada para contratacion
  AuthorizedForHiring = 'authorized-for-hiring',
  //postulacion no autorizada para contratacion
  NotAuthorizedForHiring = 'not-authorized-for-hiring',
  //postulación descartada para contratacion
  DiscardedInHiring = 'discarded-in-hiring',
  //postulación contratada
  Hired = 'hired',
  // Pendiente de contratacion
  Pending = 'pending',
}

export enum CurrentStep {
  Received = 'received',
  Resume = 'resume',
  FileUpload = 'file-upload',
  Review = 'review',
  Discarded = 'discarded',
  Approved = 'approved',
  Hired = 'hired',
}

export enum OfferStatus {
  Finalizada = 'Finalizada',
  Cerrada = 'Cerrada',
  Publicada = 'Publicada',
  Pausada = 'Pausada',
  EnProceso = 'EnProceso',
  En_Proceso = 'En proceso',
  Vigente = 'Vigente',
}

export interface File {
  id: number;
  folderName: string;
  name: string;
  extension: string;
  userId: string;
  reason: FileTypes;
  url: string;
  createdAt: string;
  isSelected?: boolean;
  certificationId?: number;
  licenseTypeId?: number;
  documentName?: string;
}

export interface Postulation {
  createdAt: string;
  currentStep: CurrentStep;
  authorizationStatus: AuthorizationStatus;
  id: number;
  offer: OfferDetail;
  updatedAt: string;
  educationalLevel?: string[];
  salaryPretension?: string | number;
  availabilityDate?: string;
  availabilityTime?: string;
  hasAdministrativeInabilities: boolean;
  hasInstitutionalInabilities: boolean;
  answer: Answer[];
  laws: Law[];
  curriculum?: File[];
  collegeDegrees?: File[];
  certifications?: File[];
  licenses?: File[];
}

export interface RequiredFile {
  id: number;
  folderName: string | null;
  name: string | null;
  extension: string | null;
  userId: number;
  reason: string | null;
  createdAt: string | null;
  requiredFiles: any[] | null;
  url: string | null;
  templateUrl?: string;
  displayName: string | null;
}

export interface GetPostulationsParams {
  itemsPerPage: number;
  page: number;
}
