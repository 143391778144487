import { FileFormField } from '../types';

/**
 * Prepara la data que será enviada en en formulario
 */
export function valuesToSubmit(values: FileFormField): FileFormField {
  return {
    ...values,
    collegeDegrees: values.collegeDegrees.filter((c) => c.isSelected),
    certifications: values.certifications.filter((c) => c.isSelected),
    licenses: values.licenses.filter((l) => l.isSelected),
  };
}
