import { useCallback } from 'react';

// Components
import PostulationsTable from './parts/PostulationsTable';
import RequiredFilesTable from './parts/RequiredFilesTable';
import { Button, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import { Card, Row, Col } from 'reactstrap';

// Hooks
import { useHistory } from 'react-router-dom';

// I18n
import { Trans, useTranslation } from 'react-i18next';

// Render
const PostulationsScreen = () => {
  const { t } = useTranslation();

  const isMobile = useMobile();
  const history = useHistory();

  const goBackToList = useCallback(() => {
    history.push('/public/jobs');
  }, [history]);

  return (
    <Row className={clsx('py-5', isMobile ? 'px-4' : 'px-5 mx-3')}>
      <Card className={clsx('py-5 w-100 mb-4', isMobile ? 'px-3' : 'px-5')}>
        <Row className="mb-5">
          <Col className="mb-4" xs={12}>
            <Button
              className="mb-3"
              icon="back"
              onClick={goBackToList}
              outlined
              rounded
              size="sm"
              text={t('views.postulations.goBackToList')}
            />
          </Col>
          <Col xs={12}>
            <span className="w-100 text-light fs-16 fw-400">
              {t('views.postulations.subtitle')}
            </span>
          </Col>
        </Row>
        <PostulationsTable />
        <Row className="mt-5 mb-3">
          <Col xs={12}>
            <div className="w-100">
              <span className="d-block w-100 text-primary fs-22 fw-700">
                {t('views.postulations.requirements.title')}
              </span>
              <span className="d-block w-100 text-light fs-16 fw-400">
                <Trans t={t}>
                  {t('views.postulations.requirements.description')}
                </Trans>
              </span>
            </div>
          </Col>
        </Row>
        <RequiredFilesTable />
      </Card>
    </Row>
  );
};

export default PostulationsScreen;
