import { useMemo, useState } from 'react';

// Components
import DisplayError from '../../../components/info/DisplayError';
import { DisplayInfo, Table } from '@octano/global-ui';

// Hooks
import usePostulationsColumns from '../hooks/usePostulationsColumns';
import { useGetPostulationsQuery } from '../api';

// I18n
import { useTranslation } from 'react-i18next';

// Types
const ITEMS_PER_PAGE = 10;

// Render
const PostulationsTable = () => {
  const { t } = useTranslation();

  const columns = usePostulationsColumns();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const postulationsData = useGetPostulationsQuery(
    {
      itemsPerPage: ITEMS_PER_PAGE,
      page: currentPage - 1,
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true },
  );

  const totalItems = useMemo(
    () => postulationsData?.data?.total || 0,
    [postulationsData?.data?.total],
  );
  const totalPages = useMemo(
    () => postulationsData?.data?.totalPages || 1,
    [postulationsData?.data?.totalPages],
  );

  return (
    <>
      <Table
        columns={columns}
        data={postulationsData?.data?.data || []}
        isLoadingResults={postulationsData?.isFetching}
        loadingView={{
          title: t(`common.loading.title`),
          subtitle: t(`common.loading.subtitle`),
        }}
        pagination={{
          currentPage: currentPage,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems,
          totalPages,
          onChangePage: (next) => setCurrentPage(next),
        }}
        noResultsText={
          postulationsData?.error ? (
            <DisplayError
              typeError={(postulationsData?.error as any)?.status}
            />
          ) : (
            <div className="d-flex flex-column align-items-center no-result">
              <DisplayInfo
                title={t('views.postulations.list.noResult')}
                textBody={t('views.postulations.list.noResultDescription')}
              />
            </div>
          )
        }
      />
    </>
  );
};

export default PostulationsTable;
