import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import SessionExpiredModal from '../components/Modal/SessionExpiredModal';
import { UserData, UserSessionEvent } from '../types/auth';
import { getJWT, isUserAuthorizedTo, parseJWT, removeJWT } from '../utils/auth';

interface UserContextType {
  userData?: UserData;
  setUserData: (newData: Partial<UserData>) => void;
  clearUser: () => void;
  hasCheckedToken: boolean;
  isAuthorizedTo: (
    requiredPermissions: string[],
    allPermisionsRequired?: boolean,
  ) => boolean;
  isLogged: boolean;
}

const DEFAULT_VALUE = {
  userData: undefined,
  hasCheckedToken: false,
  setUserData: () => null,
  clearUser: () => null,
  isAuthorizedTo: (rp: string[], allPermisionsRequired?: boolean) => true,
  isLogged: false,
};

export const UserStateContext = createContext<UserContextType>(DEFAULT_VALUE);

interface Props {
  children: ReactNode;
}

export default function UserStateProvider({ children }: Props) {
  const [user, setUser] = useState<UserData>();
  const [hasCheckedToken, setHasCheckedToken] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const setUserData = useCallback((newData: Partial<UserData>) => {
    setUser((prevData) =>
      !!prevData ? { ...prevData, ...newData } : (newData as UserData),
    );
  }, []);

  const clearUser = useCallback(() => {
    setUser(undefined);
    removeJWT();
  }, []);

  const isAuthorizedTo = useCallback(
    (requiredPermissions: string[], allPermisionsRequired?: boolean) => {
      return isUserAuthorizedTo(
        user?.permissions || [],
        requiredPermissions,
        allPermisionsRequired,
      );
    },
    [user?.permissions],
  );

  const isLogged = useMemo(() => !!user, [user]);

  useEffect(() => {
    // Manejando auth entre pestañas
    const handleStorage = async () => {
      const token = getJWT();

      if (token) {
        const data = parseJWT(token);
        setUser(() => ({ ...data }));
      } else {
        clearUser();
      }
    };

    // Inicializando segun si habia o no token almacenado
    handleStorage();
    setHasCheckedToken(true);

    // Para escuchar en el futuro los cambios del token
    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, [clearUser]);

  // Handling session expiration
  useEffect(() => {
    const notifySessionExpiration = () => {
      setShowSessionExpiredModal(true);
    };

    window.addEventListener(UserSessionEvent.EXPIRED, notifySessionExpiration);

    return () => {
      window.removeEventListener(
        UserSessionEvent.EXPIRED,
        notifySessionExpiration,
      );
    };
  }, []);

  const values = useMemo(
    () => ({
      isLogged,
      userData: user,
      setUserData,
      clearUser,
      isAuthorizedTo,
      hasCheckedToken,
    }),
    [clearUser, hasCheckedToken, isAuthorizedTo, isLogged, setUserData, user],
  );

  return (
    <UserStateContext.Provider value={values}>
      <>
        <SessionExpiredModal
          isOpen={showSessionExpiredModal}
          onConfirm={() => {
            clearUser();
            setShowSessionExpiredModal(false);
          }}
        />
        {children}
      </>
    </UserStateContext.Provider>
  );
}
