import { Button, Icon, Modal } from '@octano/global-ui';

interface Props {
  campusEmail: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function ApplicationModal({
  campusEmail = 'postulaciondocente@cftdelosrios.cl',
  isOpen,
  onClose,
}: Props) {
  return (
    <Modal isOpen={isOpen} toggle={() => false} onClosed={onClose} centered>
      <div className="d-flex flex-column align-items-center text-center mb-4">
        <Icon name="information" size={46} />
        <p className="text-dark fs-22 mt-3">Información para postular</p>
        <p className="fs-16 lh-30 mt-3">
          Para postular a esta oferta, por favor envía al correo electrónico
          <strong> {campusEmail} </strong>los siguientes requisitos:
        </p>
      </div>

      <ul className="fs-14">
        <li>
          <strong>CV Actualizado</strong> (que incluya email y teléfono de
          contacto).
        </li>
        <li>
          <strong>Disponibilidad de inicio en el cargo.</strong>
        </li>
        <li>
          <strong>Pretensión de renta.</strong>
        </li>
        <li>
          <strong>
            Documentos de validación de requisitos descritos en la oferta.
          </strong>
        </li>
      </ul>
      <div className=" my-4 text-center">
        <p className="fs-16">
          Te contactaremos a la brevedad posible para darte mayor información de
          tu postulación. Te recomendamos revisar los requisitos solicitados en
          la oferta.
        </p>
        <Button
          text="Entendido"
          fullwidth
          style={{ width: 283.7 }}
          className="m-auto"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
}
