import { useCallback, useRef } from 'react';

// Components
import ModalUploadDocument, {
  ModalUploadDocumentMethods,
} from './ModalUploadDocument';
import DisplayError from '../../../components/info/DisplayError';
import {
  Dialog,
  DisplayInfo,
  Table,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';

// Hooks
import useRequiredFilesColumns from '../hooks/useRequiredFilesColumns';

// I18n
import { useTranslation } from 'react-i18next';

// Types
import { RequiredFile } from '../types';
import { useDeleteFileMutation, useGetRequiredFilesQuery } from '../api';

// Render
const RequiredFilesTable = () => {
  const { t } = useTranslation();
  const modalUploadDocumentRef = useRef<ModalUploadDocumentMethods>(null);

  const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();

  const requirementsData = useGetRequiredFilesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const handleDestroy = useCallback(
    async (fileId: number) => {
      try {
        await deleteFile(fileId)?.unwrap();
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`views.postulations.requirements.modalDestroy.success`),
        });
        requirementsData?.refetch();
      } catch (_error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`views.postulations.requirements.modalDestroy.failed`),
        });
      }
    },
    [deleteFile, requirementsData, t],
  );

  const handleRequestDestroy = useCallback(
    (id: number) => {
      showDialogConfirm({
        title: t(`views.postulations.requirements.modalDestroy.title`),
        subtitle: t(`views.postulations.requirements.modalDestroy.subtitle`),
        btnClose: {
          text: t(`views.postulations.requirements.modalDestroy.cancel`),
        },
        btnConfirm: {
          text: t(`views.postulations.requirements.modalDestroy.confirm`),
          onConfirm: () => handleDestroy(id),
        },
      });
    },
    [t, handleDestroy],
  );

  const handleRequestUpload = useCallback((props: RequiredFile) => {
    modalUploadDocumentRef?.current?.open(props);
  }, []);

  const handleRequestDownload = useCallback((props: RequiredFile) => {
    if (props?.url) {
      window.open(props?.url, '_blank')?.focus();
    }
  }, []);

  const columns = useRequiredFilesColumns({
    onPressDestroy: handleRequestDestroy,
    onPressUpload: handleRequestUpload,
    onPressDownload: handleRequestDownload,
  });

  return (
    <>
      <Table
        columns={columns}
        data={requirementsData?.data || []}
        isLoadingResults={requirementsData?.isFetching || isDeleting}
        loadingView={{
          title: t(`common.loading.title`),
          subtitle: t(`common.loading.subtitle`),
        }}
        noResultsText={
          requirementsData?.error ? (
            <DisplayError
              typeError={(requirementsData?.error as any)?.status}
            />
          ) : (
            <div className="d-flex flex-column align-items-center no-result">
              <DisplayInfo
                title={t('views.postulations.requirements.noResult')}
                textBody={t(
                  'views.postulations.requirements.noResultDescription',
                )}
              />
            </div>
          )
        }
      />
      <Dialog />
      <ModalUploadDocument
        ref={modalUploadDocumentRef}
        onConfirm={requirementsData?.refetch}
      />
    </>
  );
};

export default RequiredFilesTable;
